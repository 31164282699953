import React, {useContext, useEffect, useState} from "react";
import SiteHistoryCard from "../../components/SiteHistoryCard";
import SiteTable from "../../components/SiteTable";
import {Link} from "react-router-dom";

// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import axios from "axios";
import {useAuth0} from "@auth0/auth0-react";
import SitewideDataContext from "../../utils/sitewideContext";

import "../../constants/service_constants";


function Site() {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [siteTime, setSiteTime] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  let queryParameters = new URLSearchParams(window.location.search);
  const site_id = queryParameters.get("site_id");
  const { sitewideData, setSitewideData } = useContext(SitewideDataContext);

  const setNewSiteTime = (newSiteTime) => {
    setSiteTime(newSiteTime);
  }
  let pages = [];

  useEffect(() => {
    async function getData() {
      const accessToken = await getAccessTokenSilently();
      let response = null;

      if (siteTime) {
        response = await axios.get(API_ADDRESS + SITE_RECENT_ENDPOINT + site_id + "/" + siteTime, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        response = await axios.get(API_ADDRESS + SITE_RECENT_ENDPOINT + site_id, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      delete response.data.site_crawl.pages[''];

      pages = Object.keys(response.data.site_crawl.pages).map((page_name) => {

        let page_name_b = null;

        try {
          page_name_b = btoa(page_name)
        } catch {
          console.error("Page name encoding failed ", page_name)
        }
        return {
          page_name: page_name,
          pdf: response.data.site_crawl.pages[page_name].pdf,
          pdf_location: response.data.site_crawl.pages[page_name].pdf_location
        };
      })

      setTitle(response.data.site_crawl.address);

      setData(
          pages.map((prop, key) => {
            if (prop.pdf === true) {
              return {
                id: key,
                page: (
                    <a href={CDN3_ADDRESS + prop.pdf_location}
                       target="_blank"
                       className="text-body"
                       title={prop.page_name}
                    >
                      <i className="fal fa-external-link"/>
                      &nbsp;&nbsp;{prop.page_name}
                    </a>),
              }
            }
            return {
              id: key,
              page:
                  <Link to={"/pagehistory?site_id="+response.data.site.site_id+"&page_id="+encodeURIComponent(prop.page_name)+"&endtime="+siteTime+"&starttime=null&site_domain="+response.data.site_crawl.address}
                        className="text-body"
                        title={prop.page_name}
                  >
                    {prop.page_name}
                  </Link>
            };
          }));
    }

    getData().finally(() => {
      setSitewideData({
        ...sitewideData,
        overloading: false
      })
    });
  }, [siteTime]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Link to={"/dashboard"}><i className="fa fa-arrow-left"/> Back to dashboard</Link>
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            <Card>
              <Card.Body>
                <SiteTable
                    data={data}
                    columns={[
                      {
                        Header: "Page",
                        accessor: "page",
                        style: { width: "80%" },
                        id: "0",
                        filter: "text",
                      }
                    ]}
                    title={title}
                    className="-striped -highlight none"
                    siteId={site_id}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3">
            <SiteHistoryCard
              setNewSiteTime={setNewSiteTime}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Site;
