import axios from 'axios';
import '../constants/service_constants'

function updateUserInfo(sitewideData, setSitewideData, getAccessTokenSilently) {
    async function fetchData() {
        try {
            const token = await getAccessTokenSilently();
            const response = await axios.get(API_ADDRESS + USER_ENDPOINT, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const userData = response.data;
            setSitewideData({ ...sitewideData, userInfo: userData });
            return true;
        } catch (error) {
            // console.error(error);
            return false;
        }
    }

    return fetchData();
}

export default updateUserInfo;