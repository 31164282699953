// API_ADDRESS = "https://dev-api.watchmypage.com";
API_ADDRESS = "https://api2.watchmypage.com";
// API_ADDRESS = "http://127.0.0.1:5050"
// API_ADDRESS = "http://127.0.0.1:5555"
// API_ADDRESS = "http://4.152.248.165"

SITES_ENDPOINT = "/sites";
USER_ENDPOINT = "/user";
PAGE_HISTORY_ENDPOINT = "/page/history/";
SITE_ENDPOINT = "/site";
SITE_RECENT_ENDPOINT = "/site/recent/";
SITE_HISTORY_ENDPOINT = "/site/history/";
PASSWORD_RESET_ENDPOINT = "/user/password_reset";
CHECK_DOMAIN_ENDPOINT = "/check-domain/";
SITE_REMOVE_USER_ENDPOINT = "/site/remove_user/";
GET_PAYMENT_LINK_ENDPOINT = "/payment_link";
GET_PORTAL_LINK_ENDPOINT = "/portal_link";

CDN1_ADDRESS = "https://cdn1.watchmypage.com/"
CDN2_ADDRESS = "https://cdn2.watchmypage.com/"
CDN3_ADDRESS = "https://cdn3.watchmypage.com/"