import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader from "../preloader";
import axios from "axios";
import {Checkbox} from "rsuite";
import {Col, Container, Row} from "react-bootstrap";


function CollectUserInfoModal({isOpen, setIsOpen, userData}) {
    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [role, setRole] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [foundUs, setFoundUs] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [legalCheckbox, setLegalCheckbox] = useState(false);
    const api = "https://api2.watchmypage.com/user";

    useEffect(() => {
        setIsLoading(false);
    }, [isOpen]);

    useEffect(() => {
        if (userData) {
            setFirstName(userData.first_name);
            setLastName(userData.last_name);
            setCompanyName(userData.company_name);
            setPhone(userData.phone_number);
            setCompanyWebsite(userData.company_website);
            setRole(userData.role);
            setCompanyType(userData.use_case);
            setCompanySize(userData.company_size);
            setFoundUs(userData.source);
        }
    }, [userData]);

    useEffect(() => {
        checkIsPhoneValid(phone);
    }, []);

    const handleSubmitClick = () => {
        setIsLoading(true);
        updateUserRequest()
    }

    const checkIsPhoneValid = (value) => {
        if (value.length === 10) {
            setIsPhoneValid(true);
        } else {
            setIsPhoneValid(false);
        }
    };

    const handlePhoneChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, '').substring(0, 10);  // removes any non-digit characters
        setPhone(rawValue);

        checkIsPhoneValid(rawValue);

        // formats the raw value for display
        e.target.value = rawValue.replace(
            /^(\d{0,3})(\d{0,3})(\d{0,4}).*/,
            (_, p1, p2, p3) => {
                let result = '';
                if (p1) result += `(${p1}`;
                if (p2) result += `) ${p2}`;
                if (p3) result += `-${p3}`;
                return result;
            }
        );  // updates the display value
    };

    function updateUserRequest(){
        axios.patch(api, {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            phone_number: phone,
            company_website: companyWebsite,
            role: role,
            legal: true,
            company_size: companySize,
            source: foundUs,
            use_case: companyType
        })
        .then(function (response) {
            alert('Your account was updated successfully!');
            setIsLoading(false);
            setIsOpen(false);
        })
        .catch(function (error) {
            alert('There was an error updating your account. Please reload the page and try again.');
            setIsLoading(false);
        });
    }

    return (
        <div>
            <Modal isOpen={isOpen} >
                <ModalHeader>Welcome to Watch My Page!</ModalHeader>
                { isLoading &&
                    <ModalBody>
                        Submitting your request...
                        <Preloader
                            text={false}
                            inline={true}
                            overlay={false}
                            logo={false}
                        />
                    </ModalBody>
                } { !isLoading &&
                    <ModalBody>
                        <Container fluid>
                            <Row>
                                <em>It looks like you're new here, let's get some information so we can get you started with the service.</em>
                                <br/><br/>
                                <Col>
                                    <Row>
                                        <Col>
                                        <FormGroup>
                                            <Label for="first_name">*First Name:</Label>
                                            <Input
                                                type="text"
                                                name="first_name"
                                                id="first_name"
                                                placeholder="John"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                // invalid={}
                                            />
                                            {/*{!isEmailValid && <div className="text-danger">Please enter a valid email address.</div>}*/}
                                        </FormGroup>
                                        </Col>
                                        <Col>
                                        <FormGroup>
                                            <Label for="last_name">*Last Name:</Label>
                                            <Input
                                                type="text"
                                                name="last_name"
                                                id="last_name"
                                                placeholder="Smith"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <FormGroup>
                                            <Label for="phone_number">*Phone Number:</Label>
                                            <Input
                                                type="tel"
                                                name="phone_number"
                                                id="phone_number"
                                                placeholder="(312) 867-5309"
                                                defaultValue={phone}  // use defaultValue instead of value
                                                onChange={handlePhoneChange}
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="role">Your Role:</Label>
                                                <Input
                                                    type="role"
                                                    name="role"
                                                    id="role"
                                                    placeholder="CEO/Founder"
                                                    value={role}
                                                    onChange={(e) => setRole(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <FormGroup>
                                            <Label for="company_name">Company Name:</Label>
                                            <Input
                                                type="text"
                                                name="company_name"
                                                id="company_name"
                                                placeholder="Watch My Page, LLC"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                            />
                                        </FormGroup>
                                        </Col>
                                        <Col>
                                        <FormGroup>
                                            <Label for="company_website">Company Website:</Label>
                                            <Input
                                                type="text"
                                                name="company_website"
                                                id="company_website"
                                                placeholder="watchmypage.com"
                                                value={companyWebsite}
                                                onChange={(e) => setCompanyWebsite(e.target.value)}
                                            />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <FormGroup>
                                            <Label for="role">What is your use case?</Label>
                                            <br/>
                                            <select value={companyType} onChange={(e) => setCompanyType(e.target.value)}>
                                                <option value="" disabled>Select an option</option>
                                                <option value="marketing">We build and maintain websites for other companies</option>
                                                <option value="self-managed">We build and maintain our own website</option>
                                                <option value="client">We employ another company to build and maintain our website</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="role">How many websites does your company manage?</Label>
                                            <br/>
                                            <select value={companySize} onChange={(e) => setCompanySize(e.target.value)}>
                                                <option value="" disabled>Select an option</option>
                                                <option value="0">None</option>
                                                <option value="1">1</option>
                                                <option value="2-10">2-10</option>
                                                <option value="11-30">11-30</option>
                                                <option value="31-100">30-100</option>
                                                <option value="101+">100+</option>
                                            </select>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="role">How did you find us?</Label>
                                            <br/>
                                            <select value={foundUs} onChange={(e) => setFoundUs(e.target.value)}>
                                                <option value="" disabled>Select an option</option>
                                                <option value="client">We are a client of a company that uses Watch My Page</option>
                                                <option value="search">Internet Search</option>
                                                <option value="linkedin">Linkedin</option>
                                                <option value="friend">Friend or Colleague</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <FormGroup>
                                            <Checkbox
                                                value="legal_checkbox"
                                                checked={legalCheckbox}
                                                onChange={(e) => setLegalCheckbox(!legalCheckbox)}>
                                                *I have read and agree to the <a href="https://watchmypage.com/terms/" target="_blank">Terms of Use</a> and <a href="https://watchmypage.com/privacy/" target="_blank">Privacy Policy</a>.
                                            </Checkbox>
                                            {!legalCheckbox && <div className="text-danger">You must agree to the Terms of Use and Privacy Policy to use the service.</div>}
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                }
                <ModalFooter>
                    { legalCheckbox && firstName && lastName && phone && isPhoneValid && !isLoading &&
                    <Button color="primary" type="submit" onClick={handleSubmitClick} className="ml-auto">
                        Submit
                    </Button> } { (!legalCheckbox || !firstName || !lastName || !phone || !isPhoneValid) && !isLoading &&
                        <em>Please fill out all of the required fields above to continue.</em>
                    }
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CollectUserInfoModal;