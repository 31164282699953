import React, {useContext, useEffect, useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import updateUserInfo from "../../utils/updateUserInfo";

import "../../constants/service_constants";

import DashboardTable from "../../components/DashboardTable";
// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col
} from "react-bootstrap";
import axios from "axios";
import SitewideDataContext from "../../utils/sitewideContext";
import CollectUserInfoModal from "../../components/Modals/CollectUserInfoModal";

let dataTable = [];

function Dashboard() {
  const { getAccessTokenSilently } = useAuth0();
  const { sitewideData, setSitewideData } = useContext(SitewideDataContext);
  const [showCollectModal, setShowCollectModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [tableMessage, setTableMessage] = useState('');

  useEffect(() => {
    if (!sitewideData.userInfo) {
      updateUserInfo(sitewideData, setSitewideData, getAccessTokenSilently);
    }
  }, []);

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently();
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  async function getUserData() {
    await getAccessToken();
    const response = await axios.get(API_ADDRESS + USER_ENDPOINT);
    // check if first_name last_name or phone_number are not filled out
    setUserData(response.data);
    if (!response.data.first_name || !response.data.last_name || !response.data.phone_number) {
      setShowCollectModal(true);
    }
  }

  useEffect(() => {
    getUserData();
    async function getData() {

      try {
        await getAccessToken();

        const response = await axios.get(API_ADDRESS + SITES_ENDPOINT);

        dataTable = [];

        response.data.sites.map((site) => {
          if (site.status) {
            dataTable.push({
              site_name: site.site_name,
              site_id: site.site_id,
              icon: "",
              site_url: site.site_url,
              pages: site.status ? site.status.pagecount : 'n/a',
              last_crawl: site.last_crawl,
              last_healthy_crawl: site.last_crawl,
              enabled: true,
              active: site.active,
              crawl_frequency: site.crawl_frequency,
              created: site.created,
              current_crawl: site.current_crawl,
              ignore_case: site.ignore_case,
              local_only: site.local_only,
              owner: site.owner,
              pageLimit: site.pageLimit,
              poll_frequency: site.poll_frequency,
              save_html: site.save_html,
              save_pdfs: site.save_pdfs,
              spider: site.spider,
              status: site.status,
              take_screenshot: site.take_screenshot,
              updated: site.updated,
              users: site.users,
            });
          } else {
            dataTable.push({
              site_id: site.site_id,
              icon: "",
              site_url: site.site_url,
              site_name: site.site_name,
              pages: 'n/a',
              last_crawl: 'Site has not been crawled yet',
              last_healthy_crawl: 'n/a',
              enabled: false,
            })
          }
        });
        if (dataTable.length === 0) {
          setTableMessage('You have no sites. Click the button below to add a new site.');
        }
      } catch (e) {
        console.error(e)
      } finally {
        setSitewideData({
          ...sitewideData,
          overloading: false
        });
      }
    }

    getData()
  }, []);

  return (
    <>
      {showCollectModal &&
          <CollectUserInfoModal
            isOpen={showCollectModal}
            setIsOpen={setShowCollectModal}
            userData={userData}
          />
      }
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Your Sites <small>({dataTable.length})</small></Card.Title>
              </Card.Header>
              <Card.Body>
                <DashboardTable
                    key={dataTable}
                    records={dataTable}
                    userInfo={userData}
                    message={tableMessage}
                    columns={[
                      {
                        Header: "Site Name",
                        accessor: "site",
                        id: "site",
                        filter: "text",
                        sortType: (rowA, rowB, columnId) => {
                          const a = rowA.original.site_value;
                          const b = rowB.original.site_value;
                          return a === b ? 0 : a > b ? 1 : -1;
                        },
                      },
                      {
                        Header: "Pages",
                        accessor: "pages",
                        id: "1",
                      },
                      {
                        Header: "Last Crawl",
                        accessor: "last_crawl",
                        id: "3",
                        sortType: (rowA, rowB, columnId) => {
                          const a = rowA.original.last_crawl_value;
                          const b = rowB.original.last_crawl_value;
                          return a === b ? 0 : a > b ? 1 : -1;
                        },
                      },
                    ]}
                    className="-striped -highlight none"
                />
                <div className="flex-column justify-content-center flex flex-wrap mt-4 mb-2 align-content-center">
                  <Link to={"addsite"}>
                    <div className="text-center w-full my-2">
                      <i className="fa-3x fa-solid fa-circle-plus text-success"></i>
                    </div>
                    <div className="text-success text-center">
                      Add a new site
                    </div>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
