import {
    Card,
    Row,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Radio, RadioGroup } from "rsuite";
import axios from "axios";
import Moment from "react-moment";
import SitewideDataContext from "../utils/sitewideContext";
import Preloader from "./preloader";
import {useAuth0} from "@auth0/auth0-react";
Moment.globalFormat = 'YYYY-MM-DD HH:mm:ss';

import "../constants/service_constants";

function SiteHistoryCard({setNewSiteTime}) {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(0);
    const {sitewideData, setSitewideData} = useContext(SitewideDataContext);

    let queryParameters = new URLSearchParams(window.location.search);

    const site_id = queryParameters.get("site_id");

    const handleSelect = (value) => {
        setSelected(value);
        setNewSiteTime(value);
    }

    useEffect(() => {
        async function getData() {
            setData([]);
            const accessToken = await getAccessTokenSilently();
            const historyResponse = await axios.get(
                API_ADDRESS + SITE_HISTORY_ENDPOINT + site_id,
                {
                headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            historyResponse.data.result.map((record, key) => {
                let site_crawl = {};
                if (key === 0) {
                    setSelected(record.start_time.toString());
                }

                site_crawl.start_time = record.start_time.toString();

                setData((prevState) => [...prevState, site_crawl]);
            })
        }

        getData().finally(() => {
            setSitewideData({
                ...sitewideData,
                overloading: false
            })
        });
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h4">History</Card.Title>
            </Card.Header>
            { data.length < 1 ? (
              <Preloader inline={true} overlay={false} />
            ) : (
              <Card.Body>
                  <em className="text-black-50" key={0}>Select a crawl to view:</em>
                  <div style={{
                      borderStyle: 'solid',
                      padding: 5,
                      marginLeft: 5,
                      marginRight: 5,
                      borderRadius: 5,
                      width: '100%',
                      overflowY: 'scroll',
                      maxHeight: 700,
                      borderColor: '#e0e0e0',
                      borderWidth: 1,
                  }}>
                      <RadioGroup
                        name="history"
                        value={selected}
                        onChange={handleSelect}
                      >
                          {data.map((record) => {
                              return(
                                <Row key={record.start_time}>
                                    <div style={{
                                        cursor: 'pointer',
                                        borderStyle: 'solid',
                                        padding: 5,
                                        margin: 15,
                                        marginTop:2,
                                        marginBottom:2,
                                        borderRadius: 5,
                                        width: '100%',
                                        textAlign: 'center',
                                        borderColor: '#e0e0e0',
                                        borderWidth: 1,
                                    }}
                                         onClick={(e) => {
                                             e.preventDefault();
                                             handleSelect(record.start_time);
                                         }}>
                                        <Radio
                                          id={record.start_time}
                                          value={record.start_time}
                                          key={record.start_time}
                                        >
                                            <Moment unix className="text-muted my-2" style={{fontSize: 14}}>
                                                {record.start_time}
                                            </Moment>
                                        </Radio>
                                    </div>
                                </Row>
                              )
                          })}
                      </RadioGroup>
                  </div>
              </Card.Body>
            )}
            <Card.Footer>
            </Card.Footer>
        </Card>
    );
}

export default SiteHistoryCard;