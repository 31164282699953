import React, {useContext, useEffect, useState} from "react";
import 'react-phone-number-input/style.css';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";
import SitewideDataContext from "../../utils/sitewideContext";

import "../../constants/service_constants";
import {Link} from "react-router-dom";

function formatPhoneNumber(phoneNumberString) {
    // Filter any non-digit characters from the input string
    const cleaned = phoneNumberString.replace(/\D/g, '');

    // Check if the cleaned phone number is valid
    const isValidPhoneNumber = /^1?\d{10}$/.test(cleaned);

    // If the phone number is valid, format it using regular expressions
    if (isValidPhoneNumber) {
        const intlCode = cleaned.startsWith('1') ? '+1 ' : '';
        const areaCode = cleaned.slice(1, 4);
        const prefix = cleaned.slice(4, 7);
        const lineNumber = cleaned.slice(7, 11);
        return [intlCode, '(', areaCode, ') ', prefix, '-', lineNumber].join('');
    }
    // If the phone number is invalid, return null
    return "";
}

function Account() {
  const [accountData, setAccountData] = useState({
    first_name: "",
    last_name: "",
    user_id: "",
    company_name: "",
    phone_number: "",
    role: "",
    company_website: "",
    site_licenses: 0,
    sites_owner: []
  });
  const [newAccountData, setNewAccountData] = useState(accountData);
  const {
    getAccessTokenSilently
  } = useAuth0();
  const {sitewideData, setSitewideData} = useContext(SitewideDataContext);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [stripePortalLink, setStripePortalLink] = useState('');

  const handlePasswordResetClick = (e) => {
    e.preventDefault();
    getAccessToken();
    const response = axios.get(API_ADDRESS + PASSWORD_RESET_ENDPOINT)
        .then((response) => {
          alert("Please check your email for a password reset link.");
        }).catch((error) => {
            alert("There was an error resetting your password. Please refresh the page and try again.");
        });
  }

  const handlePhoneNumberChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setNewAccountData({...newAccountData, phone_number: e.target.value});
    setIsValidPhoneNumber(formattedNumber !== '');
  };

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently();
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  useEffect(() => {
    async function getData() {
      await getAccessToken();
      const response = await axios.get(API_ADDRESS + USER_ENDPOINT + "?include_sites=true");
      setAccountData(response.data);
      setNewAccountData(response.data);
      if (response.data.self_service) {
        setStripePortalLink(response.data.portal_link);
      }
    }

    getData().finally(() => {
      setSitewideData({
        ...sitewideData,
        overloading: false
      })
    });
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Form action="#" method="#">
                  <Row>
                    <Col>
                      <h5 className="text-center">Account Details</h5>
                      <hr/>
                      <Form.Group>
                        <label>First Name</label>
                        <Form.Control defaultValue={newAccountData.first_name}
                                      key={newAccountData.first_name} disabled></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Last Name</label>
                        <Form.Control defaultValue={newAccountData.last_name}
                                      key={newAccountData.last_name} disabled></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Email Address</label>
                        <Form.Control defaultValue={newAccountData.user_id} disabled
                                      key={newAccountData.user_id}></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Contact Phone Number</label><br/>
                        <em className="text-muted">This is not the phone number we use for authentication.</em>
                        <Form.Control
                          type="tel"
                          placeholder="Enter phone number"
                          value={newAccountData.phone_number}
                          onChange={handlePhoneNumberChange}
                          isInvalid={!isValidPhoneNumber}
                          maxLength={10}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid phone number.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <label>Company Name</label>
                        <Form.Control defaultValue={newAccountData.company_name}
                                      key={newAccountData.company_name} disabled></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Role</label>
                        <Form.Control defaultValue={newAccountData.role}
                                      key={newAccountData.role} disabled></Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <label>Company Website</label>
                        <Form.Control defaultValue={newAccountData.company_website}
                                      key={newAccountData.company_website}
                                      disabled></Form.Control>

                      </Form.Group>
                      <br/>
                      <br/>
                      <div className="text-center">
                        <h5>Security Settings:</h5>
                        <Form.Group className="">
                          <br/><br/>
                          <Button
                              className="btn-fill btn-wd mx-4"
                              type="submit"
                              variant="info"
                              onClick={handlePasswordResetClick}>
                            Change Password
                          </Button>
                          {/*<br/><br/>*/}
                          {/*<Button className="btn-fill btn-wd mx-4" type="submit" variant="info">Enable*/}
                          {/*  2FA</Button>*/}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col className="text-center">
                      <div>
                        <h5>Licenses</h5>
                        <hr/>
                      </div>
                      <div className="m-3">
                        Current Licenses: {accountData.site_licenses}
                      </div>
                      <div className="m-3">
                        Licenses Used: {accountData.owned_sites}
                      </div>
                      <div className="m-3">
                          Licenses Available: {accountData.site_licenses - accountData.owned_sites}
                      </div>
                      {stripePortalLink ? (
                          <Button
                              className="btn-fill btn-wd mx-4 my-3 mb-5"
                              type="submit"
                              variant="info"
                              href={stripePortalLink}>
                            Manage Subscription
                          </Button>
                      ) : null}
                      <h5>Sites</h5>
                      <hr/>
                      <table className="table table-bordered table-condensed">
                        <thead className="text-primary">
                        <tr>
                          <th>
                            Site Name
                          </th>
                          <th>
                            Role
                          </th>
                          <th>
                            Actions
                          </th>
                        </tr>
                        </thead>
                        <tbody className="text-monospace">
                        {accountData.sites_owner ? accountData.sites_owner.map((site, index) => (
                          <tr key={index}>
                            <td>
                              {site.site_name}&nbsp;&nbsp;
                              {site.active ? <small>(active)</small> : <small>(inactive)</small>}
                            </td>
                            <td>
                              Owner
                            </td>
                            <td>
                              <Link to={"sitedetails?site_id=" + site.site_id}>Manage</Link>&nbsp;&nbsp;
                              <Link to={"site?site_id=" + site.site_id}>View Archives</Link>
                            </td>
                          </tr>
                        )) : null}
                        {accountData.sites_admin ? accountData.sites_admin.map((site, index) => (
                          <tr key={index}>
                            <td>
                              {site.site_name}&nbsp;&nbsp;
                              {site.active ? <small>(active)</small> : <small>(inactive)</small>}
                            </td>
                            <td>
                              Admin
                            </td>
                            <td>
                              <Link to={"sitedetails?site_id=" + site.site_id}>Manage</Link>&nbsp;&nbsp;
                              <Link to={"site?site_id=" + site.site_id}>View Archives</Link>
                            </td>
                          </tr>
                        )) : null}
                        {accountData.sites_view ? accountData.sites_view.map((site, index) => (
                          <tr key={index}>
                            <td>
                              {site.site_name}&nbsp;&nbsp;
                              {site.active ? <small>(active)</small> : <small>(inactive)</small>}
                            </td>
                            <td>
                              View
                            </td>
                            <td>
                              <Link to={"sitedetails?site_id=" + site.site_id}>Manage</Link>&nbsp;&nbsp;
                              <Link to={"site?site_id=" + site.site_id}>View Archives</Link>
                            </td>
                          </tr>
                        )) : null}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  {/*<Row>*/}
                  {/*  <Col className="text-center mt-4">*/}
                  {/*    <Button className="btn-fill btn-wd" type="submit" variant="secondary" disabled>Save</Button>*/}
                  {/*  </Col>*/}
                  {/*</Row>*/}
                </Form>
              </Card.Body>
              <Card.Footer/>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Account;
