import React, {useEffect, useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader from "../preloader";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";


function AddUserModal({isOpen, setIsOpen, siteId, setUpdatePage}) {
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [selectedRole, setSelectedRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { user, getAccessTokenSilently } = useAuth0();
    const api = "https://api2.watchmypage.com/site/add_user/";

    useEffect(() => {
        setIsLoading(false);
        setEmail('');
        setIsEmailValid(true);
        setSelectedRole('');
    }, [isOpen]);

    const toggle = () => {
        !isLoading ? setIsOpen(false) : null;
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setIsEmailValid(validateEmail(e.target.value));
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const handleRoleSelect = (role) => {
        setSelectedRole(role);
    };

    const handleSubmitClick = () => {
        setIsLoading(true);
        addUserRequest(siteId, email, selectedRole);
    }

    function addUserRequest(siteId, emailAddress, role){
        axios.post(api + siteId, {
            user_email: emailAddress,
            role: role
        })
        .then(function (response) {
            alert('User invited successfully!');
            setIsLoading(false);
            setIsOpen(false);
            setEmail('');
            setIsEmailValid(true);
            setSelectedRole('');
            setUpdatePage(Date.now());
        })
        .catch(function (error) {
            alert('There was an error inviting the user. Please reload the page and try again.');
            setIsLoading(false);
            setIsOpen(false);
            setEmail('');
            setIsEmailValid(true);
            setSelectedRole('');
        });
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Invite a user</ModalHeader>
                { isLoading &&
                    <ModalBody>
                        Submitting your request...
                        <Preloader
                            text={false}
                            inline={true}
                            overlay={false}
                            logo={false}
                        />
                    </ModalBody>
                } { !isLoading &&
                    <ModalBody>
                        <FormGroup>
                            Please enter the email address of the user you would like to invite to this site.
                            <br/>
                            <em>
                                (If the user does not already have an account they will be sent an invite to create one.)
                            </em>
                            <br/>
                            <Label for="email">Email:</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@example.com"
                                value={email}
                                onChange={handleEmailChange}
                                invalid={!isEmailValid}
                            />
                            {!isEmailValid && <div className="text-danger">Please enter a valid email address.</div>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="role">Role:</Label>
                            <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                                <option value="" disabled>Select a role</option>
                                <option value="admin">Admin</option>
                                <option value="view">View</option>
                            </select>
                        </FormGroup>
                    </ModalBody>
                }
                <ModalFooter>
                    {isEmailValid && selectedRole && !isLoading &&
                    <Button color="primary" type="submit" onClick={handleSubmitClick} className="ml-auto">
                        Submit
                    </Button>
                    } {!isEmailValid || !selectedRole &&
                    <p>Please enter a valid email and select a role.</p>
                    }
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AddUserModal;