import React, {useContext, useEffect, useState} from "react";

// react-bootstrap components
import {
  Alert,
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import { Checkbox } from "rsuite";
import { useAuth0 } from "@auth0/auth0-react";
import SitewideDataContext from "../../utils/sitewideContext";
import AddUserModal from "../../components/Modals/AddUserModal";
import "../../constants/service_constants";

function overlayItem(text) {
  return(
      <Tooltip id="statusItem">
        {text}
      </Tooltip>
  )
}

function checkUserPermissions(user, site) {
  let isAdmin = 0;

  if (user.email === site.owner) {
    isAdmin = 2;
  } else {
    site.users.forEach((siteUser) => {
      if (user.email === siteUser.user_id) {
        if (siteUser.role === "admin" || siteUser.role === "super_admin") {
          isAdmin = 1;
        }
      }
    });
  }

  return isAdmin
}

function SiteDetails() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [viewOnly, setViewOnly] = useState(true);
  const [siteData, setSiteData] = useState({});
  const [newSiteData, setNewSiteData] = useState({
    site_url: "Domain of the site you're adding",
    site_name: "Friendly Name of the site you're adding",
    sitemap_url: "https://www.example.com/sitemap.xml",
    owner: "You will be the owner of any site you create."
  });
  let queryParameters = new URLSearchParams(window.location.search);
  let site_id = queryParameters.get("site_id");
  const [isValidURL, setIsValidURL] = useState(true);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [updatePage, setUpdatePage] = useState(0);

  const { sitewideData, setSitewideData } = useContext(SitewideDataContext);

  const addUserClick = () => {
    setAddUserOpen(true);
  }

  async function getAccessToken() {
    const accessToken = await getAccessTokenSilently();
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  const handleDomainChange = (e) => {
    const domain = e.target.value.trim();
    const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (domainRegex.test(domain)) {
      setNewSiteData({ ...newSiteData, domain: domain });
      setIsValidURL(true);
    } else {
      setNewSiteData({ ...newSiteData, domain: domain });
      setIsValidURL(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    async function getData() {
      await getAccessToken()
      const response = await axios.get(API_ADDRESS + SITE_ENDPOINT + '/' + site_id);
      setSiteData(response.data);
      setNewSiteData(response.data);

        if (checkUserPermissions(user, response.data) === 0) {
            setViewOnly(true);
        } else {
            setViewOnly(false);
        }
    }

    getData().finally(() => {
      setSitewideData({
        ...sitewideData,
        overloading: false
      })
    });
  }, [site_id, updatePage]);

  const handleRemoveUser = (user_id) => {
    if (confirm("Are you sure you want to remove this user?")) {
      axios.post(API_ADDRESS + SITE_REMOVE_USER_ENDPOINT + site_id, {
        user_email: user_id
      }).then((response) => {
        alert("User removed successfully");
        setUpdatePage(Date.now());
      }).catch((error) => {
        alert("Error removing user");
      });
    } else {
      return false;
    }
  }

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <Row>
                <Col>
                  {siteData.site_name ? siteData.site_name : "Add a new site"}
                </Col>
                <Col>
                  { siteData.site_name ? (
                        <Alert variant="danger" className="my-0 py-0">
                            You are viewing this site with {checkUserPermissions(user, siteData) === 0 ? "view only" : "admin"} permissions.  {checkUserPermissions(user, siteData) === 0 ? "\n To edit the site please contact the site owner listed below." : null}
                        </Alert>
                      )
                    : null
                  }
                </Col>
              </Row>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Container fluid>
              <Row className="flex-row">
                <Col className="col-lg-6">
                  <h5>Site Details</h5>
                  <hr/>
                  <Form.Group>
                    <label>Name&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Friendly name of the site you want to crawl, like 'Watch My Page'.  We just use this to help you manage your sites more easily - it doesn't affect crawling.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger></label>
                    <Form.Control
                        defaultValue={newSiteData.site_name}
                        key={newSiteData.site_name}
                        disabled={viewOnly}
                    />
                  </Form.Group>
                  <br/>
                  <Form.Group>
                    <label>Domain&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("This is the actual domain of the site you want to crawl.  Must be a valid domain or subdomain with no scheme, protocol, port, path, params or file.  Like 'www.watchmypage.com' or 'watchmypage.com'.  If you use a CNAME record to redirect to your root domain, then you should use whatever level your A record is.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger></label>
                    <Form.Control
                        defaultValue={ newSiteData.site_url }
                        isInvalid={!isValidURL&&!viewOnly}
                        onChange={handleDomainChange}
                        key={newSiteData.site_url}
                        disabled={viewOnly}
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" hidden={viewOnly}>Please enter a valid domain.</Form.Control.Feedback>
                  </Form.Group>
                  {/*<div className="text-center">*/}
                  {/*  <Button className="btn-fill btn-wd" type="submit" variant="primary">Test Scan</Button>*/}
                  {/*  <br/>*/}
                  {/*  <em>Performs a quick scan to check for any issues</em>*/}
                  {/*</div>*/}
                  <br/><br/>
                  <h5>Site Permissions</h5>
                  <hr/>
                  <h6>Owner:</h6>
                  <em>{siteData.owner}</em>
                  <br/><br/>
                  <h6>Users with access:</h6>
                  <table className="table table-condensed table-bordered">
                    <thead>
                      <tr className="text-center">
                          <th>User ID</th>
                          <th>Role</th>
                          <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {siteData.users ? siteData.users.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {user.user_id}
                            </td>
                            <td className="text-center">
                              <select value={user.role} disabled={true}>
                                <option value="" disabled>Select a role</option>
                                <option value="admin">Admin</option>
                                <option value="view">View</option>
                              </select>
                            </td>
                            <td className="text-center">
                              {!viewOnly &&
                                  <i
                                    className="fal fa-trash-alt"
                                    onClick={() => handleRemoveUser(user.user_id)}
                                    style={{cursor: "pointer"}}
                                />
                              }
                            </td>
                          </tr>
                        );
                      }) : <tr><td>No users have access to this site.</td></tr> }
                    </tbody>
                  </table>
                  <br/>
                  {!viewOnly && <Button className="btn btn-simple" onClick={addUserClick} type="submit" variant="primary">Invite User</Button>}
                </Col>
                <Col className="col-lg-6">
                  <h5>Scan Settings</h5>
                  <hr/>
                  <Checkbox
                      className="ml-4"
                      defaultChecked={newSiteData.save_pdfs}
                      id="mute_email"
                      disabled={true}
                  >
                    <span className="ml-4">Save internal PDF files&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Enabling this feature means we will download and store PDF files that are linked and hosted on your site.  With this disabled, we will still check whether the PDF is available, but we won't archive a copy of the file.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <div>
                    <Checkbox
                        className="ml-4"
                        defaultChecked={newSiteData.save_pdfs}
                        id="mute_email"
                        disabled={true}
                    >
                    <span className="ml-4">Save internal PDF files&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Enabling this feature means we will download and store PDF files that are linked and hosted on your site.  With this disabled, we will still check whether the PDF is available, but we won't archive a copy of the file.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                    </Checkbox>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-4" key={siteData.site_name}>
                  {!viewOnly && <Button className="btn-fill btn-wd" type="submit" variant="primary">Save Changes</Button>}
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer/>
        </Card>
        <AddUserModal
          isOpen={addUserOpen}
          setIsOpen={setAddUserOpen}
          siteId={siteData.site_id}
          setUpdatePage={setUpdatePage}
        />
      </Container>
    </>
  );
}

export default SiteDetails;
