/*eslint-disable*/
import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  useTable,
  useSortBy,
  useFilters,
} from "react-table";
import classnames from "classnames";
import Moment from "react-moment";
import Preloader from "./preloader";
Moment.globalFormat = 'YYYY-MM-DD HH:mm:ss';

const safeStringify = (value) => {
    try {
        // Check if value is an object or an array
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value, null, 2); // The last argument is for pretty printing
        }
        return String(value);
    } catch (error) {
        return ''; // or some fallback value you deem appropriate
    }
};

function Table({ columns, records, message }) {
    if (window.location.href.includes("debug")) {
        columns.push({
            Header: "Debug Info",
            accessor: "debug_info",
            id: "debug_info",
            Cell: ({value}) => (
                <div style={{
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    maxWidth: "300px"
                }}>
                    {value}
                </div>
            )
        });
    }

    const [data] = useState(
        records.map((site, key) => {
            return {
                id: key,
                icon: (<img
                    src={require("assets/img/thumb.ico")}
                    alt="logo"
                ></img>),
                site: site.active ? (<Link to={"site?site_id=" + site.site_id}>{site.site_name}</Link>) :
                  (<><Link to={"site?site_id=" + site.site_id}>{site.site_name}</Link>&nbsp;&nbsp;<small>disabled (click to edit)</small></>),
                site_value: site.site_name.toLowerCase(),
                pages: site.pages,
                last_crawl: (<Moment unix>{site.last_crawl}</Moment> ),
                last_crawl_value: site.last_crawl,
                last_healthy_crawl: (<Moment unix>{site.last_healthy_crawl}</Moment> ),
                last_healthy_crawl_value: site.last_healthy_crawl,
                enabled: site.enabled,
                debug_info: (<>
                    active: {safeStringify(site.active)}  <br />
                    crawl_frequency: {safeStringify(site.crawl_frequency)}  <br />
                    created: {safeStringify(site.created)}  <br />
                    current_crawl: {safeStringify(site.current_crawl)}  <br />
                    ignore_case: {safeStringify(site.ignore_case)}  <br />
                    last_crawl: {safeStringify(site.last_crawl)}  <br />
                    last_healthy_crawl: {safeStringify(site.last_healthy_crawl)}  <br />
                    latency_issues: {safeStringify(site.latency_issues)}  <br />
                    local_only: {safeStringify(site.local_only)}  <br />
                    owner: {safeStringify(site.owner)}  <br />
                    pageLimit: {safeStringify(site.pageLimit)}  <br />
                    poll_frequency: {safeStringify(site.poll_frequency)}  <br />
                    save_html: {safeStringify(site.save_html)}  <br />
                    save_pdfs: {safeStringify(site.save_pdfs)}  <br />
                    site_id: {safeStringify(site.site_id)}  <br />
                    site_name: {safeStringify(site.site_name)}  <br />
                    site_url: {safeStringify(site.site_url)}  <br />
                    spider: {safeStringify(site.spider)}  <br />
                    status: {safeStringify(site.status)}  <br />
                    take_screenshot: {safeStringify(site.take_screenshot)}  <br />
                    updated: {safeStringify(site.updated)}  <br />
                    users: {safeStringify(site.users)}
                </>)
            };
        }).sort((a, b) => {
            if (a.site_value < b.site_value) { return -1; }
            if (a.site_value > b.site_value) { return 1; }
            return 0;
        })
    );

    const handleSearchInputChange = (event) => {
      const value = event.target.value || "";
      setFilter("site", value.toLowerCase());
    };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes: {
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id].props.children || "";
            if (typeof rowValue !== "string") {
              return false;
            }
            return rowValue.toLowerCase().includes(filterValue.toLowerCase());
          });
        },
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
  );

  return (
    <>
      {
        rows.length === 0 ? (
            message ? (
                <div className="text-center">{message}</div>
            ) : (
                <Preloader inline={true} overlay={false}/>
            )
        ) : (
          <div className="ReactTable -striped -highlight primary-pagination">
            <div className="flex-row flex-nowrap">
              <input placeholder="Search your sites" className="navbar-search-form float-right col-4" onChange={handleSearchInputChange}></input>
            </div>
            <table {...getTableProps()} className="rt-table">
              <thead className="rt-thead -header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                  {headerGroup.headers.map((column, key) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={classnames("rt-th text-left", {
                        "-cursor-pointer": [0, 1, 3, 4].includes(key),
                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                        "-sort-desc": column.isSorted && column.isSortedDesc,
                      })}
                    >
                      <div className="rt-resizable-header-content">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody {...getTableBodyProps()} className="rt-tbody">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={classnames(
                      "rt-tr",
                      { " -odd": i % 2 === 0 },
                      { " -even": i % 2 === 1 }
                    )}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="rt-td">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        )
      }
    </>
  );
}

export default Table;
