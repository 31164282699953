import React from "react";
import {Switch, Route, Redirect} from "react-router-dom";

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Preloader from "../components/preloader";

// dynamically create dashboard routes
import routes from "routes.js";

import { withAuthenticationRequired } from "@auth0/auth0-react";

export const Admin = () => {
  const [sitewideData] = React.useState({
    headerTitle: "Dashboard",
    userInfo: {},
    breadcrumb: ["Dashboard"],
    overloading: false
  });

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper">
        {sitewideData.overloading ? <Preloader logo overlay text/> : null}
        <Sidebar
          routes={routes}
        />
        <div className="main-panel">
          {/*<AdminNavbar/>*/}
          <div className="content">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/dashboard"/>
            </Switch>
          </div>
          <AdminFooter/>
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}


export default withAuthenticationRequired(Admin);