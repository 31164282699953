import React, {useContext, useEffect, useState} from "react";
import 'react-phone-number-input/style.css';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
Chart.register(ArcElement, Tooltip, Legend);


// react-bootstrap components
import {
    Card,
    Container,
    Row,
    Col,
    Table
} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";

function AdminData() {
    const {
        getAccessTokenSilently
    } = useAuth0();
    const [uncrawledSites, setUncrawledSites] = useState([]);
    const [uncrawledSitesCount, setUncrawledSitesCount] = useState(0);
    const [crawledSitesCount, setCrawledSitesCount] = useState(0);
    const [inactiveSitesCount, setInactiveSitesCount] = useState(0);
    const [dailyCrawlStatus, setDailyCrawlStatus] = useState([]);
    const [last30Days, setLast30Days] = useState([]);

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently();
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    useEffect(() => {
        async function getData() {
            await getAccessToken();
            return await axios.get('https://api2.watchmypage.com/admin-data');
        }

        getData().then(result => {
            setUncrawledSitesCount(result.data.sites.filter(site => site.active && site.crawled_today === false).length);
            setCrawledSitesCount(result.data.sites.filter(site => site.active && site.crawled_today === true).length);
            setInactiveSitesCount(result.data.sites.filter(site => site.active === false).length);
            setUncrawledSites(result.data.sites.filter(site => site.active && site.crawled_today === false));
        }).finally(() => {
        });
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4">Daily Crawl Status</Card.Title>
                            </Card.Header>
                            <Card.Body className="text-center">
                                <Doughnut
                                    data={{
                                        labels: [
                                            'Completed',
                                            'Uncrawled',
                                            'Inactive'
                                        ],
                                        datasets: [{
                                            // label: 'Today\'s Crawl Status',
                                            data: [crawledSitesCount, uncrawledSitesCount, inactiveSitesCount],
                                            backgroundColor: [
                                                'rgb(54, 162, 235)',
                                                'rgb(255, 99, 132)',
                                                'rgb(255, 205, 86)'
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                ></Doughnut>
                            </Card.Body>
                            <Card.Footer>
                                <hr/>
                                <div className="stats">
                                    <i className="fas fa-history"/> Updated 3 minutes ago
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4">Uncrawled Sites</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Site</th>
                                            <th>Owner</th>
                                            <th>Days Dead</th>
                                            <th>Add to queue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {uncrawledSites.map((site, index) => (
                                            <tr key={index}>
                                                <td>{site.site_url}</td>
                                                <td>{site.owner}</td>
                                                <td>{site.days_dead}</td>
                                                <td><a href=""><i className="fas fa-plus-circle"/></a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Card.Body>
                            <Card.Footer>
                                <hr/>
                                <div className="stats">
                                    <i className="fas fa-history"/> Updated 3 minutes ago
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title as="h4">Last 30 Days</Card.Title>
                            </Card.Header>
                            <Card.Body>

                            </Card.Body>
                            <Card.Footer>
                                <hr/>
                                <div className="stats">
                                    <i className="fas fa-history"/> Updated 3 minutes ago
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AdminData;
