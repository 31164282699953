import {
    Card,
    Row,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Checkbox } from "rsuite";
import axios from "axios";
import Moment from "react-moment";
import SitewideDataContext from "../../utils/sitewideContext";
import {useAuth0} from "@auth0/auth0-react";
import {Buffer} from 'buffer';
import "../../constants/service_constants";

Moment.globalFormat = 'YYYY-MM-DD HH:mm:ss';

function HistoryCard({setNewHTMLSourceURLs, start_time, end_time, siteDomain}) {
    const { getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState([]);
    const [selectedRecordsCount, setSelectedRecordsCount] = useState(0);
    const {sitewideData, setSitewideData} = useContext(SitewideDataContext);

    let queryParameters = new URLSearchParams(window.location.search);

    const site_id = queryParameters.get("site_id");
    const page_id = decodeURIComponent(queryParameters.get("page_id"));
    var str_page_id = Buffer.from(page_id).toString('base64');

    const handleDisableCheckboxes = () => {
        setData(oldData => {
            return oldData.map(record => {
                if (selectedRecordsCount >= 2 && !record.selected) {
                    return { ...record, disabled: true };
                } else {
                    return { ...record, disabled: false };
                }
            });
        });
    };

    const handleSetHTMLSourceURLs = () => {
        try{
            let queryParameters = new URLSearchParams(window.location.search);
            const selectedRecords = data.filter(record => record.selected);
            if (selectedRecords.length < 2) {
                setNewHTMLSourceURLs(
                    selectedRecords[0].html_source,
                    null,
                    selectedRecords[0].start_time,
                    null);
                queryParameters.set("starttime", selectedRecords[0].start_time);
                queryParameters.set("endtime", 'null');
            } else {
                setNewHTMLSourceURLs(
                    selectedRecords[0].html_source,
                    selectedRecords[1].html_source,
                    selectedRecords[0].start_time,
                    selectedRecords[1].start_time);
                queryParameters.set("starttime", selectedRecords[0].start_time);
                queryParameters.set("endtime", selectedRecords[1].start_time);
            }
            window.history.pushState({}, "", "?" + queryParameters.toString());
        } catch (e) {
        }
    }

    function handleSelect(startTime) {
        setData(prevData => {
            const newData = prevData.map(record => {
                if (record.start_time === startTime && !record.disabled) {
                    return { ...record, selected: !record.selected };
                } else {
                    return record;
                }
            });
            setSelectedRecordsCount(newData.filter(record => record.selected).length);
            return newData;
        });
    }

    useEffect(() => {
        handleDisableCheckboxes();
        if (selectedRecordsCount > 0 && selectedRecordsCount < 3) {
            handleSetHTMLSourceURLs();
        }
    }, [selectedRecordsCount]);

    const handleCheckboxChange = (event, other, syntheticEvent) => {
        const newSelected = syntheticEvent.target.checked;
        const startTime = syntheticEvent.target.id;
        setData(prevData => {
            const newData = prevData.map(record => {
                if (record.start_time.toString() === startTime) {
                    return { ...record, selected: newSelected };
                } else {
                    return record;
                }
            });
            setSelectedRecordsCount(newData.filter(record => record.selected).length);
            return newData;
        });

        syntheticEvent.stopPropagation();
    };

    useEffect(() => {
        async function getData() {
            setData([]);
            const accessToken = await getAccessTokenSilently();
            const historyResponse = await axios.get(
                API_ADDRESS + PAGE_HISTORY_ENDPOINT + site_id + "/" + str_page_id,
                {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            historyResponse.data.page_history.map((record, key) => {
                    let page_crawl = {};
                    page_crawl.html_length = '';
                    page_crawl.selected = false;
                    page_crawl.disabled = false;
                    if (end_time) {
                        if (record.start_time === end_time) {
                            page_crawl.selected = true;
                            page_crawl.disabled = false;
                            setSelectedRecordsCount(selectedRecordsCount + 1);
                        }
                        if (start_time) {
                            if (record.start_time === start_time) {
                                page_crawl.selected = true;
                                page_crawl.disabled = false;
                                setSelectedRecordsCount(selectedRecordsCount + 1);
                            }
                        }
                    } else {
                        page_crawl.selected = key < 1;
                        page_crawl.disabled = key >= 1;
                        setSelectedRecordsCount(1);
                    }
                    page_crawl.html_source = record.pages[str_page_id].html_location;
                    page_crawl.screenshot = record.pages[str_page_id].screenshot_location;

                    page_crawl.html_length = record.pages[str_page_id].html_length ? record.pages[str_page_id].html_length : '';

                    page_crawl.start_time = record.start_time;


                    setData((prevState) => [...prevState, page_crawl]);
            })
        }

        getData().finally(() => {
            setSitewideData({
                ...sitewideData,
                overloading: false
            })
        });
    }, []);

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h4">History</Card.Title>
            </Card.Header>
            <Card.Body>
                <em className="text-black-50" key={0}>Select a crawl or compare two:</em>
                <div style={{
                    borderStyle: 'solid',
                    padding: 5,
                    marginLeft: 5,
                    marginRight: 5,
                    borderRadius: 5,
                    width: '100%',
                    overflowY: 'scroll',
                    maxHeight: 700,
                    borderColor: '#e0e0e0',
                    borderWidth: 1,
                }}>
                    {data.map((record, key) => {
                        return(
                            <Row key={record.start_time}>
                                <div style={{
                                    cursor: 'pointer',
                                    borderStyle: 'solid',
                                    padding: 5,
                                    margin: 15,
                                    marginTop:2,
                                    marginBottom:2,
                                    borderRadius: 5,
                                    width: '100%',
                                    textAlign: 'center',
                                    borderColor: record.selected ? '#a0a0a0' : '#e0e0e0',
                                    borderWidth: 1,
                                }}
                                     onClick={(e) => {
                                         e.preventDefault();
                                         handleSelect(record.start_time);
                                     }}>
                                    <Checkbox
                                        id={record.start_time}
                                        disabled={record.disabled}
                                        checked={record.selected}
                                        onChange={handleCheckboxChange}
                                        key={record.start_time}
                                    >
                                        <Moment unix className="text-muted my-2" style={{fontSize: 14}}>
                                            {record.start_time}
                                        </Moment>
                                        <br/>
                                        <small className="text-muted">Size: {record.html_length ? record.html_length : 'n/a'} chars</small>
                                    </Checkbox>
                                </div>
                            </Row>
                        )
                    })}
                </div>
            </Card.Body>
            <Card.Footer>
            </Card.Footer>
        </Card>
    );
}

export default HistoryCard;