/*eslint-disable*/
import React, { useState, useMemo } from "react";
import {
    useTable,
    useSortBy,
    useFilters,
} from "react-table";
import classnames from "classnames";
import Preloader from "./preloader";
import siteDetails from "../views/Pages/SiteDetails";
import {Link} from "react-router-dom";

function Table({ columns, data, title, siteId }) {

    const defaultColumn = useMemo(
        () => ({
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes: {
                text: (rows, id, filterValue) => {
                    return rows.filter((row) => {
                        const rowValue = row.values[id].props.children || "";
                        if (typeof rowValue !== "string") {
                            return false;
                        }
                        return rowValue.toLowerCase().includes(filterValue.toLowerCase());
                    });
                },
            },
        },
        useFilters,
        useSortBy,
    );

    const [searchInput, setSearchInput] = useState("");

    const handleSearchInputChange = (event) => {
        const value = event.target.value || "";
        setSearchInput(value);
        setFilter("0", value.toLowerCase()); // add toLowerCase here
    };

    return (
        <>
            <div className="ReactTable -striped -highlight primary-pagination">
                <div className="flex-row flex-nowrap">
                    {title ? "Pages of " + title : "Data Loading..."}
                    <Link to={"/sitedetails?site_id="+siteId}>
                        <i className="fa fa-cog ml-2"></i><small>Manage Site</small>
                    </Link>
                    <input
                        placeholder="Search pages"
                        className="navbar-search-form float-right col-4"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                    />
                </div>
                {!title ? (
                  <Preloader inline={true} overlay={false}/>
                ) : (
                  <table {...getTableProps()} className="rt-table">
                      <thead className="rt-thead -header">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                            {headerGroup.headers.map((column, key) => (
                              <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={classnames("rt-th", {
                                    "-cursor-pointer": key === 0,
                                    "-sort-asc": column.isSorted && !column.isSortedDesc,
                                    "-sort-desc": column.isSorted && column.isSortedDesc,
                                })}
                                style={ key === 0 ? {width: '70%'} : {width: '30%'} }
                              >
                                  <div className="">
                                      {column.render("Header")}
                                  </div>
                              </th>
                            ))}
                        </tr>
                      ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="rt-tbody">
                      {rows.map((row, i) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              className={classnames(
                                "rt-tr",
                                { " -odd": i % 2 === 0 },
                                { " -even": i % 2 === 1 }
                              )}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                      <td {...cell.getCellProps()} className="rt-td">
                                          {cell.render("Cell")}
                                      </td>
                                    );
                                })}
                            </tr>
                          );
                      })}
                      </tbody>
                  </table>
                )}
            </div>
        </>
    );
}

export default Table;
