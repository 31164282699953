import {
  Container,
} from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu">
              <li>
                <a href="https://watchmypage.com/terms.html" target="_blank">
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="https://watchmypage.com/privacy.html" target="_blank">
                  Privacy Policy
                </a>
              </li>
            </ul>
            <p className="copyright text-center">
              © 2023 |
              <a href="https://watchmypage.com"> Watch My Page</a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
