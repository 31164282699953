/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

//WMP IMPORTS
import SiteDetails from "./views/Pages/SiteDetails";
import Dashboard from "./views/Pages/Dashboard";
import Account from "./views/Pages/Account";
import Site from "./views/Pages/Site";
import PageHistory from "./views/Pages/PageHistory";
import AddSite from "./views/Pages/AddSite";
import AdminData from "./views/Pages/AdminData";

let routes = [
  {
    path: "dashboard",
    layout: "/",
    name: "Dashboard",
    icon: "fa-thin fa-monitor-waveform",
    component: Dashboard,
    reload: true
  },
  {
    path: "site",
    layout: "/",
    name: "Site",
    icon: "fa-thin fa-list-tree",
    component: Site,
    hidden: true,
    reload: false
  },
  {
    path: "account",
    layout: "/",
    name: "Account",
    icon: "fa-thin fa-user",
    component: Account,
    reload: false
  },
  {
    path: "addsite",
    layout: "/",
    name: "Add Site",
    icon: "fa-thin fa-plus-circle",
    component: AddSite,
    reload: false
  },
  {
    path: "pagehistory",
    layout: "/",
    name: "Page History",
    icon: "fa-thin fa-rectangle-history",
    component: PageHistory,
    hidden: true,
    reload: false
  },
  {
    path: "sitedetails",
    layout: "/",
    name: "Site Details",
    icon: "fa-thin fa-rectangle-history",
    component: SiteDetails,
    hidden: true,
    reload: false
  },
  {
    path: "admindata",
    layout: "/",
    name: "Admin Data",
    icon: "fa-thin fa-rectangle-history",
    component: AdminData,
    hidden: true,
    reload: false
  },
];
export default routes;
