import React, { useState, useEffect } from "react";
import quotes from "../constants/quotes";
import "./Preloader.css";
import { Loader } from "rsuite";

const Preloader = ({ text, inline, overlay, logo, textOverride }) => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
  const [ellipsis, setEllipsis] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setQuoteIndex((prevIndex) => {
        let newIndex;
        do {
          newIndex = getRandomQuoteIndex();
        } while (newIndex === prevIndex);
        return newIndex;
      });
      setAnimationKey((prevKey) => prevKey + 1);
    }, 6000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const ellipsisTimer = setInterval(() => {
      setEllipsis((prevEllipsis) => {
        if (prevEllipsis.length < 3) {
          return prevEllipsis + ".";
        } else {
          return "";
        }
      });
    }, 700);

    return () => clearInterval(ellipsisTimer);
  }, []);

  const getRandomQuoteIndex = () => {
    return Math.floor(Math.random() * quotes.length);
  };

  return (
    <div
      style={overlay ? {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 10000,
        height: inline ? "100%" : "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 1)"
      } : {
        width: "100%",
        height: inline ? "100%" : "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div style={{ textAlign: 'center' }}>
        {logo ? (
          <img src="/logo.png"
               style={{
                 display: 'block',
                 marginLeft: 'auto',
                 marginRight: 'auto',
                 maxWidth: '300px',
               }}
                alt="logo"
          />
        ) : (
          <Loader size="sm" speed="slow" />
        )}
        <br/>
        {text && (
          <div className="quote-container">
            <h4>{textOverride ? textOverride : "Loading"}{ellipsis}</h4>
            <br/><br/>
            <span
              className={`quote-text fade-rotate-${animationKey % 2}`}
              key={quoteIndex}
            >
              {quotes[quoteIndex]}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Preloader;
