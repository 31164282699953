import React, { useState } from "react";
import DiffCard from "../../components/PageHistory/DiffCard";
import IssuesCard from "../../components/PageHistory/IssuesCard";
import HistoryCard from "../../components/PageHistory/HistoryCard";
import { Link } from 'react-router-dom';

import "../../constants/service_constants";

import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

function PageHistory() {
  const [htmlSourceURL1, setHTMLSourceURL1] = useState(null);
  const [htmlSourceURL2, setHTMLSourceURL2] = useState(null);
  let queryParameters = new URLSearchParams(window.location.search);
  const site_id = queryParameters.get("site_id");
  const page_id = decodeURIComponent(queryParameters.get("page_id"));
  const siteDomain = queryParameters.get("site_domain");
  const start_time = parseInt(queryParameters.get("starttime"), 10);
  const end_time = parseInt(queryParameters.get("endtime"), 10);
  const [timestamp1, setTimestamp1] = useState(null);
  const [timestamp2, setTimestamp2] = useState(null);

  const setNewHTMLSourceURLs = (newURL1, newURL2, newTimestamp1, newTimestamp2) => {
    setHTMLSourceURL1(CDN2_ADDRESS+newURL1)
    setHTMLSourceURL2(CDN2_ADDRESS+newURL2)
    setTimestamp1(newTimestamp1)
    setTimestamp2(newTimestamp2)
  };

  function downloadPDF() {

    const date1 = new Date(timestamp1 * 1000);
    const date2 = new Date(timestamp2 * 1000);

    const formattedDate1 = `${date1.getFullYear()}-${date1.getMonth()+1}-${date1.getDate()}_${date1.getHours()}-${date1.getMinutes()}-${date1.getSeconds()}`;
    const formattedDate2 = `${date2.getFullYear()}-${date2.getMonth()+1}-${date2.getDate()}_${date2.getHours()}-${date2.getMinutes()}-${date2.getSeconds()}`;

    document.title = "Page History - " + siteDomain + page_id + " - " + formattedDate1 + " - " + formattedDate2;

    setTimeout(() => {
      window.print();
      document.title = "Watch My Page";
    }, 200);

  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Link to={"/site?site_id=" + site_id}><i className="fa fa-arrow-left"/> Back to {siteDomain}</Link>
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            <div id="pdf-content">
              <IssuesCard
                  timestamp1={timestamp1}
                  timestamp2={timestamp2}
                  site_id={site_id}
                  page_id={page_id}
                  siteDomain={siteDomain}
                  key={(timestamp1 + timestamp2 + 1)}
                  downloadPDF={downloadPDF}
              />
              <DiffCard
                  key={(htmlSourceURL1 + htmlSourceURL2 + 2)}
                  htmlSourceURL1={htmlSourceURL1}
                  htmlSourceURL2={htmlSourceURL2}
                  pageURL={page_id}
                  siteDomain={siteDomain}
                  timestamp1={timestamp1}
                  timestamp2={timestamp2}
              />
            </div>
          </Col>
          <Col lg="3">
            <HistoryCard
                setNewHTMLSourceURLs={setNewHTMLSourceURLs}
                start_time={start_time}
                end_time={end_time}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PageHistory;
