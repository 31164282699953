/*!
=========================================================
* Light Bootstrap Dashboard PRO React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import SitewideDataContext from "./utils/sitewideContext";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.js";

Sentry.init({
    dsn: "https://b837dbfbc1e843e1add9e3f117c2600b@o4504975379922944.ingest.sentry.io/4504977073766400",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const App = () => {
  const [sitewideData, setSitewideData] = React.useState({
    overloading: true,
  });

  return (
    <Auth0Provider
      domain="auth.watchmypage.com"
      clientId="hCBEQ23QShEo78hxI81Ao5kpxkEaJ55B"
      redirectUri={window.location.origin + "/dashboard"}
      audience="https://api.watchmypage.com"
      // onRedirectCallback={onRedirectCallback}
      // useRefreshTokens={true}
      // cacheLocation="localstorage"
    >
      <SitewideDataContext.Provider value={{sitewideData, setSitewideData}}>
        <BrowserRouter>
          <Switch>
            <Route path="/" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter>
      </SitewideDataContext.Provider>
    </Auth0Provider>
  );
};

root.render(<App />);