import React, {useEffect, useRef, useState,} from "react";
// react component used to create charts
import ReactDiffViewer from 'react-diff-viewer';
import axios from 'axios';
import {Checkbox} from "rsuite";
import axiosRetry from 'axios-retry';
// react-bootstrap components
import {Card, Collapse, Image,} from "react-bootstrap";
import Moment from "react-moment";
import Preloader from "../preloader";

axiosRetry(axios, {retries: 3});
let newErrorMsg = "An error was encountered fetching the new HTML file, please refresh the page or select a different date.";
let oldErrorMsg = "An error was encountered fetching the old HTML file, please refresh the page or select a different date.";

function stripHtmlTags(html) {
  let result = html;
  result = result.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
  result = result.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');
  result = result.replace(/<[^>]*>/g, '');
  result = result.replace(/<[^>]*>|<!--[\s\S]*?-->|\/\/.*|\/\*[\s\S]*?\*\//g, '');
  result = result.replace(/^\s*$\n/gm, '');
  return result;
}

function DiffCard({htmlSourceURL1, htmlSourceURL2, pageURL, timestamp1, timestamp2, siteDomain}) {
  const [oldHTML, setOldHTML] = useState(["Loading old source HTML", "Loading stripped old source HTML"]);
  const [newHTML, setNewHTML] = useState(["Loading new source HTML", "Loading stripped new source HTML"]);
  const [strip, setStrip] = useState(0);
  const [stripCheck, setStripCheck] = useState(false);
  const [wordDiff, setWordDiff] = useState(false);
  const [wordDiffCheck, setWordDiffCheck] = useState(false);
  const [expandLocal, setExpandLocal] = useState(false);
  const [expandLocalCheck, setExpandLocalCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [diffCollapse, setDiffCollapse] = useState(true);

  let oldHTMLSourceURL1 = "";
  let oldHTMLSourceURL2 = "";

  const diffViewerRef = useRef(null);

  useEffect(() => {
      if (diffViewerRef.current) {
          setIsLoading(false);
      } else {
          setIsLoading(true);
      }
  }, [strip, wordDiff, expandLocal]);

  const handleStripChange = (value = null) => {
    setIsLoading(true);
    setStripCheck(value === null ? stripCheck === false : value);
    setTimeout(() => {
      setStrip(value === null ? strip === 0 ? 1 : 0 : value);
    }, 500);
  };

  const handleWordDiffChange = (value = null) => {
    setIsLoading(true);
    setWordDiffCheck(value === null ? wordDiffCheck === false : value);
    setTimeout(() => {
      setWordDiff(value === null ? wordDiff === false : value);
    }, 500);
  };

  const handleExpandChange = (value = null) => {
    setIsLoading(true);
    setExpandLocalCheck(value === null ? expandLocalCheck === false : value);
    setTimeout(() => {
      setExpandLocal(value === null ? expandLocal === false : value);
    }, 500);
  };

  useEffect(() => {
    if (diffCollapse) {
      return;
    }

    setIsLoading(true);
    let instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];

    async function getDiffs() {
      if (htmlSourceURL1 !== oldHTMLSourceURL1) {
        await instance.get(htmlSourceURL1).then(
          response => {
            if (response.data) {
              setNewHTML([response.data, stripHtmlTags(response.data)]);
            } else {
              setNewHTML([newErrorMsg, newErrorMsg]);
            }
          }
        ).catch(error => {
          setNewHTML([newErrorMsg, newErrorMsg])
        });
      }

      if (timestamp2) {
        if (htmlSourceURL2 !== oldHTMLSourceURL2) {
          await instance.get(htmlSourceURL2).then(
            response => {
              if (response.data) {
                  setOldHTML([response.data, stripHtmlTags(response.data)]);
              } else {
                  setOldHTML([oldErrorMsg, oldErrorMsg]);
              }
          }).catch(error => {
            setOldHTML([oldErrorMsg, oldErrorMsg]);
          });
        }
      } else {
        setOldHTML(["", ""]);
      }
    }

    getDiffs().finally(() => {
      setIsLoading(false);
      oldHTMLSourceURL2 = htmlSourceURL2;
      oldHTMLSourceURL1 = htmlSourceURL1;
    });
  }, [htmlSourceURL2, htmlSourceURL1, timestamp2, timestamp1, diffCollapse]);

  const highlightSyntax = str => <pre
    style={{display: 'inline'}}
    dangerouslySetInnerHTML={{__html: Prism.highlight(str, Prism.languages.javascript)}}
  />;

  const downloadFirst = () => {
    const blob = new Blob([oldHTML], {type: "text/html;charset=utf-8"});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = siteDomain + pageURL + '_' + timestamp1 + '.html';
    link.click();
  }

  const downloadSecond = () => {
    const blob = new Blob([newHTML], {type: "text/html;charset=utf-8"});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = siteDomain + pageURL + '_' + timestamp2 + '.html';
    link.click();
  }

  return (
    <Card className="pb-3">
      <Card.Header>
        <Card.Title as="h4">
        <a
            aria-expanded={diffCollapse}
            data-toggle="collapse"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setDiffCollapse(!diffCollapse);
            }}
        >
            Source Code - {siteDomain+pageURL}<b className={diffCollapse ? "fa fa-caret-up" : "fa fa-caret-down"}></b>
        </a>
    </Card.Title>
        <Card.Subtitle as="em">
          { !diffCollapse && (timestamp2 ?
                <>
                  <button className="download-button ml-auto" onClick={downloadFirst}>
                    <i className="fa fa-download"/>
                  </button>
                  &nbsp;
                  First Scan: <Moment unix>{timestamp2}</Moment>
                  &nbsp;&nbsp;
                  <i className="fa fa-arrow-right"/>
                  &nbsp;&nbsp;
                  <button className="download-button ml-auto" onClick={downloadSecond}>
                    <i className="fa fa-download"/>
                  </button>
                  &nbsp;
                  Second Scan: <Moment unix>{timestamp1}</Moment>
                </>
              :
                <>
                <button className="download-button ml-auto" onClick={downloadSecond}>
                  <i className="fa fa-download"/>
                </button>
                &nbsp;
                  Scan: <Moment unix>{timestamp1}</Moment>
                </>)
        }
        </Card.Subtitle><br/>
        {/*<Checkbox onChange={() => {setDiffCollapse(!diffCollapse); setIsLoading(false); diffViewerRef.current = true}} checked={diffCollapse}>*/}
        {/*  Hide Source Code Section*/}
        {/*</Checkbox>*/}
        <hr></hr>
      </Card.Header>
      <Card.Body className="position-relative" style={!diffCollapse ? {minHeight: 150} : null}>
        {!diffCollapse ? (
            <div>
          <span>
            <Checkbox onChange={handleStripChange} checked={stripCheck}>
              Strip HTML Tags
            </Checkbox>
          </span>
          <span>
            <Checkbox onChange={handleWordDiffChange} checked={wordDiffCheck}>
              Enable Word Diff
            </Checkbox>
          </span>
          <span>
            <Checkbox onChange={handleExpandChange} checked={expandLocalCheck}>
              Expand All
            </Checkbox>
          </span>
          <br/>
          <br/>
            {(!diffViewerRef.current || isLoading) ? (
              <div
                className="position-absolute"
                style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 1,
                }}
              >
                  <Preloader simple text />
              </div>
            ) : null}
            <ReactDiffViewer
              styles={{
                line: {
                  wordBreak: "break-word"
                }
              }}
              key={(strip, wordDiff, expandLocal)}
              oldValue={oldHTML[strip]}
              newValue={newHTML[strip]}
              splitView={false}
              renderContent={strip === 0 ? highlightSyntax : null}
              disableWordDiff={!wordDiff}
              showDiffOnly={!expandLocal}
              ref={diffViewerRef}
            />
            </div>) :
            <div className="text-center">
                <em>Please click above to expand the source code section.</em>
            </div>
        }
      </Card.Body>
    </Card>
  )
}

export default DiffCard;