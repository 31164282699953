import {Card, Collapse, Image} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import "yet-another-react-lightbox/styles.css";
import Moment from "react-moment";
import {useAuth0} from "@auth0/auth0-react";
import Preloader from "../preloader";
import {Buffer} from 'buffer';

import "../../constants/service_constants";

Moment.globalFormat = 'YYYY-MM-DD HH:mm:ss';


function getStatusColor(status) {
    if (status < 400) {
        return "text-success";
    } else if (status === 666) {
        return "text-secondary";
    } else {
        return "text-danger";
    }
}

function IssuesCard({timestamp1, timestamp2, site_id, page_id, downloadPDF, siteDomain}) {
    const [expandScreenshot1, setExpandScreenshot1] = useState(false);
    const [expandScreenshot2, setExpandScreenshot2] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [pageHistoryData, setPageHistoryData] = useState({
        loading: true,
        page_id: page_id,
    });
    const [expandScreenshot1Load, setExpandScreenshot1Load] = useState(false);
    const [expandScreenshot2Load, setExpandScreenshot2Load] = useState(false);

    useEffect(() => {
        if (timestamp1 === null) {
            return;
        }
        async function getData() {

            const accessToken = await getAccessTokenSilently();

            let str_page_id = Buffer.from(page_id).toString('base64');

            let l_timestamp2 = timestamp2 !== null ? timestamp2 : '0';

            const response = await axios.get(API_ADDRESS + PAGE_HISTORY_ENDPOINT + site_id + "/" + str_page_id + "/" + timestamp1 + "/" + l_timestamp2, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            if (timestamp2 !== null) {
                let first_crawl = response.data.page_history[1];
                let first_page_crawl = first_crawl.pages[str_page_id];
                let second_crawl = response.data.page_history[0];
                let second_page_crawl = second_crawl.pages[str_page_id];

                setPageHistoryData({
                    first_screenshot: CDN1_ADDRESS+first_page_crawl.screenshot_location,
                    second_screenshot: CDN1_ADDRESS+second_page_crawl.screenshot_location,
                    title: page_id,
                    first_status: first_page_crawl.status,
                    first_latency: first_page_crawl.poll_microseconds,
                    first_start_time: first_crawl.start_time,
                    second_status: second_page_crawl.status,
                    second_latency: second_page_crawl.poll_microseconds,
                    second_start_time: second_crawl.start_time,
                    loading: false,
                });
            } else {

                let second_crawl = response.data.page_history[0];
                let second_page_crawl = second_crawl.pages[str_page_id];

                setPageHistoryData({
                    second_screenshot: CDN1_ADDRESS+second_page_crawl.screenshot_location,
                    title: page_id,
                    second_status: second_page_crawl.status,
                    second_latency: second_page_crawl.poll_microseconds,
                    second_start_time: second_crawl.start_time,
                    loading: false,
                });
            }
        }

        getData();
    }, [timestamp1, timestamp2]);

    return (
        <Card className="pb-3">
            <Card.Header >
                <div>
                    <Card.Title as="h4">Page Scan Summary - {siteDomain+page_id}</Card.Title>
                    <Card.Subtitle as="em" className="d-flex justify-content-between align-items-center">
                        {
                            timestamp2 ?
                            <>First Scan: <Moment unix>{timestamp2}</Moment>&nbsp;&nbsp;<i className="fa fa-arrow-right" />&nbsp;&nbsp;Second Scan: <Moment unix>{timestamp1}</Moment></>
                                :
                            <>Scan: <Moment unix>{timestamp1}</Moment></>
                        }
                        <button className="download-button ml-auto" onClick={downloadPDF}>
                            <i className="fa fa-download"/>
                        </button>
                    </Card.Subtitle>
                </div>
                <hr/>
            </Card.Header>
            {pageHistoryData.loading ? (
              <Card.Body>
                  <Preloader inline={true} overlay={false}/>
              </Card.Body>
            ) : (
                <Card.Body>
                    {
                        timestamp2 ?
                            <><strong>Latency:</strong> {Math.round(Math.abs(pageHistoryData.first_latency/1000))}ms <i className="fa fa-arrow-right" /> {Math.round(Math.abs(pageHistoryData.second_latency/1000))}ms<br/></>
                            :
                            <><strong>Latency:</strong> {Math.round(Math.abs(pageHistoryData.second_latency/1000))}ms<br/></>

                    }
                    {
                        timestamp2 ?
                            <><strong>Status:</strong> <code className={getStatusColor(pageHistoryData.first_status)}> {pageHistoryData.first_status}</code>&nbsp;<i className="fa fa-arrow-right" />&nbsp;<code className={getStatusColor(pageHistoryData.second_status)}>{pageHistoryData.second_status}</code><br/><br/></>
                            :
                            <><strong>Status:</strong> <code className={getStatusColor(pageHistoryData.second_status)}>{pageHistoryData.second_status}</code><br/><br/></>
                    }
                    { timestamp2 &&
                    <>
                        <a
                          aria-expanded={expandScreenshot1}
                          data-toggle="collapse"
                          href="#"
                          onClick={(e) => {
                              e.preventDefault();
                              setExpandScreenshot1(!expandScreenshot1);
                              setExpandScreenshot1Load(true);
                          }}
                        >
                            <strong>First Screenshot</strong> <b className={expandScreenshot1 ? "fa fa-caret-down" : "fa fa-caret-up"}></b><br/><br/>
                        </a>
                        <Collapse
                          className="card-collapse"
                          id="collapseOneHover"
                          in={expandScreenshot1}
                        >
                            <div>
                                { expandScreenshot1Load &&
                                    <Image src={pageHistoryData.first_screenshot} className="img-container"/>
                                }
                            </div>
                        </Collapse>
                    </>
                    }

                    <a
                      aria-expanded={expandScreenshot2}
                      data-toggle="collapse"
                      href="#"
                      onClick={(e) => {
                          e.preventDefault();
                          setExpandScreenshot2(!expandScreenshot2);
                          setExpandScreenshot2Load(true);
                      }}
                    >
                        { timestamp2 ?
                            <><strong>Second Screenshot</strong> <b className={expandScreenshot2 ? "fa fa-caret-down" : "fa fa-caret-up"}></b><br/><br/></>
                            :
                            <><strong>Screenshot</strong> <b className={expandScreenshot2 ? "fa fa-caret-down" : "fa fa-caret-up"}></b><br/><br/></>
                        }
                    </a>
                    <Collapse
                      className="card-collapse"
                      id="collapseOneHover"
                      in={expandScreenshot2}
                    >
                        <div>
                            { expandScreenshot2Load &&
                                <Image src={pageHistoryData.second_screenshot} className="img-container"/>
                            }
                        </div>
                    </Collapse>
                </Card.Body>
            )}
        </Card>
    );
}

export default IssuesCard;