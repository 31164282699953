let quotes = [
  "This should only take a moment...",
  "\"I'm not lazy, I'm just in beta mode.\" - Unknown",
  "\"The code is so clean, you can eat off of it. But please don't, because it's still full of bugs.\" - Unknown",
  "\"It's not a bug, it's an undocumented feature.\" - Unknown",
  "\"We don't make mistakes, we just have unexpected features.\" - Unknown",
  "\"My code doesn't always work, but when it does, I don't know why.\" - Unknown",
  "\"We use the latest cutting-edge technology... from 1998.\" - Unknown",
  "\"If at first you don't succeed, call it version 1.0.\" - Unknown",
  "\"I don't always test my code, but when I do, I do it in production.\" - Unknown",
  "\"It's not a bug, it's a rite of passage.\" - Unknown",
  // "\"I'm not arguing, I'm just explaining why I'm right.\" - Unknown",
  "\"The code works on my machine.\" - Unknown",
  "\"If it compiles, ship it!",
  "\"Real programmers don't document. If it was hard to write, it should be hard to understand.\" - Unknown",
  "\"Code never lies, comments sometimes do.\" - Unknown",
  "\"Good code is its own best documentation. Bad code needs lots of comments.\" - Unknown",
  "\"I have not failed. I've just found 10,000 ways that won't work.\" - Unknown",
  "\"I don't always write bugs, but when I do, I write them in production code.\" - Unknown",
  "\"My code is like poetry... nobody understands it.\" - Unknown",
  "\"It's not a hack, it's a workaround.\" - Unknown",
  "\"It's not a problem, it's an opportunity to learn.\" - Unknown",
  "\"I don't always test my code, but when I do, I do it in production.\" - Unknown",
  "\"Code never lies, comments sometimes do.\" - Unknown",
  "\"I don't need Stack Overflow, I have a PhD in copy-pasting.\" - Unknown",
  "\"Code that works on your machine might not work on your boss's machine, that's why we have dev and prod.\" - Unknown",
  "\"The best way to predict the future is to write code so poorly that it can only be maintained by you.\" - Unknown",
  // "\"Debugging is like being the detective in a crime movie where you are also the murderer.\" - Unknown",
  // "\"Code is like humor, if you have to explain it, it's not that good.\" - Unknown",
  // "\"The only thing worse than no code is bad code. And the only thing worse than bad code is your code.\" - Unknown",
  // "\"I'm not lazy, I'm just conserving energy for the next bug hunt.\" - Unknown",
  // "\"Coding is easy, debugging is hard. And documenting is the hardest.\" - Unknown",
  // "\"Coding is like art, it takes skill, patience, and a lot of caffeine.\" - Unknown",
  // "\"I don't always write unit tests, but when I do, I make sure they fail before the code is even written.\" - Unknown",
  // "\"Code reviews are like sex: it's always better when you're not the only one doing it.\" - Unknown",
  // "\"There are two ways to write error-free programs; only the third one works.\" - Unknown",
  // "\"I'm not a great coder, I'm just really good at Googling.\" - Unknown",
  // "\"Every program can be reduced by one instruction, and every program has at least one bug. Therefore, every program can be reduced to one instruction that doesn't work.\" - Unknown",
  // "\"Code is like a joke, if you have to explain it, it's not for everyone.\" - Unknown",
  // "\"The only thing that's more overrated than Bitcoin is the latest JavaScript framework.\" - Unknown",
  // "\"There are three ways to write software: the right way, the wrong way, and my way, which is the wrong way, but faster.\" - Unknown",
  // "\"Debugging is like being lost in a forest of code, except you have no compass, no map, and it's on fire.\" - Unknown",
  // "\"I love deadlines. I like the whooshing sound they make as they fly by.\" - Douglas Adams",
  // "\"Software is like sex: it's better when it's free.\" - Linus Torvalds",
  // "\"Debugging is like being a detective in a crime movie where you're also the murderer.\" - Filipe Fortes",
  // "\"Programmers don't die, they just go offline.\" - Unknown",
  // "\"I don't always test my code, but when I do, I do it in production.\" - Unknown",
  // "\"Programming is like playing the piano; everyone can play the keys, but few can create music.\" - Unknown",
  // "\"Any code of your own that you haven't looked at for six or more months might as well have been written by someone else.\" - Eagleson's law",
  // "\"Programming is easy. It's just typing, typing, typing... and then it's debugging.\" - Unknown",
  // "\"There are 10 types of people in this world: those who understand binary, and those who don't.\" - Unknown",
  // "\"I would love to change the world, but they won't give me the source code.\" - Unknown",
  // "\"It works on my machine.\" - Unknown",
  // "\"If at first, you don't succeed, call it version 1.0.\" - Unknown",
  // "\"Documentation is like sex; when it's good, it's very, very good, and when it's bad, it's better than nothing.\" - Dick Brandon",
  // "\"Software engineering: turning coffee into code.\" - Unknown",
  // "\"There's no place like 127.0.0.1.\" - Unknown",
  // "\"Programmers are tools for converting caffeine into code.\" - Unknown",
  // "\"Code never lies, comments sometimes do.\" - Ron Jeffries",
  // "\"Debugging: removing needles from haystacks.\" - Unknown",
  // "\"Software is a great combination of artistry and engineering.\" - Bill Gates",
  // "\"In theory, theory and practice are the same. In practice, they're not.\" - Yogi Berra",
  // "\"Debugging is like being a detective in a crime movie where you're also the murderer, and the victim, and the witness.\" - Filipe Fortes",
  // "\"The only thing worse than a poorly written program is one that's too well documented.\" - Unknown",
  // "\"I'm not a programmer, I'm a bug hunter.\" - Unknown",
  // "\"If at first you don't succeed, try, try, try, try, try, try, try, try, try, try again, then give up. There's no use being a damn fool about it.\" - W.C. Fields",
  // "\"Code is like a box of chocolates, you never know what you're going to get.\" - Forrest Gump (paraphrased)",
  // "\"In coding, as in life, sometimes the only way to move forward is to take one step back and try again.\" - Unknown",
  // "\"Debugging is like an onion, it has many layers and it makes you cry.\" - Unknown",
  // "\"The best code is no code at all.\" - Unknown",
  // "\"If you can't beat 'em, debug 'em.\" - Unknown",
  // "\"Programming is the art of telling a computer what to do, and then arguing with it when it doesn't.\" - Unknown",
  // "\"Code is like a poem, it's not just what's written, it's also what's left unsaid.\" - Unknown",
  // "\"A computer once beat me at chess, but it was no match for me at kickboxing.\" - Emo Philips",
  // "\"It's not the code you write that counts, it's the code you delete.\" - Unknown",
  // "\"Good code is like a good wine, it takes time and patience to mature.\" - Unknown",
  // "\"I'm not saying it was aliens, but it was probably a bug in my code.\" - Unknown",
  // "\"Programming is like a game of Tetris, the more mistakes you make, the faster things fall apart.\" - Unknown",
  // "\"Code is poetry, but sometimes it's more like Vogon poetry.\" - Unknown",
  // "\"If you think coding is hard, try debugging.\" - Unknown",
  // "\"Debugging is like hunting for a needle in a haystack, while blindfolded and riding a unicycle.\" - Unknown",
  // "\"The only thing harder than debugging code is debugging other people's code.\" - Unknown",
  // "\"Code is like a baby, it needs constant attention and care, and sometimes it keeps you up all night.\" - Unknown",
  // "\"The only way to do great work is to love what you do. If you haven't found it yet, keep looking. Don't settle. As with all matters of the heart, you'll know when you find it.\" - Steve Jobs",
  // "\"Code should be like a good joke, it should explain itself.\" - Unknown",
  // "\"Debugging is like eating an elephant, one bite at a time.\" - Unknown",
  // "\"If debugging is the process of removing software bugs, then programming must be the process of putting them in.\" - Edsger Dijkstra",
  // "\"Good code is like a good haircut, it looks effortless but it takes skill to pull off.\" - Unknown",
  // "\"Programs must be written for people to read, and only incidentally for machines to execute.\" - Harold Abelson and Gerald Jay Sussman",
  // "\"There's no such thing as a bug-free program, only programs that are bug-free for now.\" - Unknown",
  // "\"A computer once beat me at chess, but it was no match for me at kickboxing.\" - Emo Philips",
  // "\"There are two ways to write error-free programs; only the third one works.\" - Alan J. Perlis",
  // "\"Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live.\" - John F. Woods",
  // "\"It's not the code you write that matters, it's the code you delete.\" - Unknown",
  // "\"Software developers are the rock stars of the information age.\" - Unknown",
  // "\"Programmers are tools for converting caffeine into code.\" - Unknown",
  // "\"Real programmers don't comment their code. If it was hard to write, it should be hard to understand.\" - Unknown",
  // "\"Programming is like sex: one mistake and you have to support it for the rest of your life.\" - Michael Sinz",
  // "\"Code is read much more often than it is written.\" - Guido van Rossum",
  // "\"Java is to JavaScript what car is to carpet.\" - Chris Heilmann",
  // "\"Debugging is like being the doctor in a hospital full of hypochondriacs.\" - Unknown",
  // "\"Software is like entropy: it is difficult to grasp, weighs nothing, and obeys the second law of thermodynamics; i.e., it always increases.\" - Norman Ralph Augustine",
  // "\"Programmers are constantly facing off against the universe with a small supply of rubber bands and duct tape.\" - Unknown",
  // "\"The best way to get a project done faster is to start sooner.\" - Jim Highsmith",
  // "\"Software is a gas; it expands to fill its container.\" - Nathan Myhrvold",
  // "\"The computer was born to solve problems that did not exist before.\" - Bill Gates",
  // "\"One of the main causes of the fall of the Roman Empire was that, lacking zero, they had no way to indicate successful termination of their C programs.\" - Robert Firth",
  // "\"Debugging is like a dance between you, your code, and the computer.\" - Unknown",
  // "\"Programming is like playing the violin; everyone can play the notes, but it takes a master to make music.\" - Unknown",
  // "\"Most software today is very much like an Egyptian pyramid with millions of bricks piled on top of each other, with no structural integrity, but just done by brute force and thousands of slaves.\" - Alan Kay",
  // "\"The best way to predict the future is to implement it.\" - David Heinemeier Hansson",
  // "\"Debugging is twice as hard as writing the code in the first place. Therefore, if you write the code as cleverly as possible, you are, by definition, not smart enough to debug it.\" - Brian Kernighan",
  // "\"If debugging is the process of removing software bugs, then programming must be the process of putting them in.\" - Edsger Dijkstra",
  // "\"The difference between theory and practice is that in theory, there is no difference between theory and practice, but in practice, there is.\" - Jan L. A. van de Snepscheut",
  // "\"The best code is no code at all.\" - Jeff Atwood",
  // "\"A good programmer is someone who always looks both ways before crossing a one-way street.\" - Doug Linder",
  // "\"The best thing about a boolean is even if you are wrong, you are only off by a bit.\" - Anonymous",
  // "\"If you can't solve a problem, then there is an easier problem you can solve: find it.\" - George Polya",
  // "\"One man's constant is another man's variable.\" - Alan Perlis",
  // "\"C++: Where friends have access to your private members.\" - Gavin Russell Baker",
  // "\"Computers are like bikinis. They save people a lot of guesswork.\" - Sam Ewing",
  // "\"A computer lets you make more mistakes faster than any other invention in human history, with the possible exceptions of handguns and tequila.\" - Mitch Ratcliffe",
  // "\"Most software today is very much like an Egyptian pyramid with millions of bricks piled on top of each other, with no structural integrity, but just done by brute force and thousands of slaves.\" - Alan Kay",
  // "\"Software is a gas; it expands to fill its container.\" - Nathan Myhrvold",
  // "\"Programming is not about what you know; it's about what you can figure out.\" - Chris Pine",
  // "\"Debugging is like being a doctor; you get to diagnose the problem, but not fix it.\" - Unknown",
  // "\"Programming is like writing a book; it's hard work up front, but once you're done, people can enjoy it for years to come.\" - Unknown",
  // "\"The best way to predict the future is to implement it.\" - David Heinemeier Hansson",
  // "\"I'm not a great programmer; I'm just a good programmer with great habits.\" - Kent Beck",
  // "\"Software is a great combination of artistry and engineering.\" - Bill Gates",
  // "\"Programs must be written for people to read, and only incidentally for machines to execute.\" - Harold Abelson",
  // "\"A computer program does what you tell it to do, not what you want it to do.\" - Unknown",
  // "\"In programming, the hard part isn't solving problems, but deciding what problems to solve.\" - Paul Graham",
  // "\"The three most dangerous things in the world are a programmer with a soldering iron, a hardware engineer with a software patch, and a user with an idea.\" - Unknown",
  // "\"The best way to get a project done faster is to start sooner.\" - Jim Highsmith",
  // "\"Programming is a race between programmers, who try to make more and more idiot-proof programs, and the universe, which produces more and more remarkable idiots.\" - Unknown",
  "\"The difference between a bug and a feature is the documentation.\" - Unknown",
  "\"Programming is not a zero-sum game. Teaching something to a fellow programmer doesn't take it away from you.\" - John Carmack",
  "\"If debugging is the process of removing software bugs, then programming must be the process of putting them in.\" - Edsger Dijkstra",
  "\"The sooner you start to code, the longer the program will take.\" - Roy Carlson",
  "\"Most good programmers do programming not because they expect to get paid or get adulation by the public, but because it is fun to program.\" - Linus Torvalds",
  "\"Programming is not about typing, it's about thinking.\" - Rich Hickey",
  "\"A language that doesn't affect the way you think about programming is not worth knowing.\" - Alan Perlis",
  "\"Programming can be fun, so can cryptography; however, they should not be combined.\" - Benoit Mandelbrot",
  "\"If you want to go somewhere, it is best to find someone who has already been there.\" - Robert Kiyosaki",
  "\"Any sufficiently advanced technology is indistinguishable from magic.\" - Arthur C. Clarke",
  "\"Debugging is like an onion. You peel off one layer at a time, and sometimes you cry.\" - Unknown",
  "\"Good code is its own best documentation. As you’re about to add a comment, ask yourself, ‘How can I improve the code so that this comment isn’t needed?’ - Steve McConnell",
  "\"The computer is incredibly fast, accurate, and stupid; the human is incredibly slow, inaccurate, and brilliant; together they are powerful beyond imagination.\" - Albert Einstein",
  "\"Every programmer is an author.\" - Cory Doctorow",
  "\"Being different is one of the most beautiful things on earth.\" - Cory Doctorow",
  "\"The Internet isn't a thing, it's a dream we share.\" - Cory Doctorow",
  // "\"The difference between the NSA and Facebook is the difference between the KGB and a drunken teenager.\" - Cory Doctorow",
  "\"If you don't like someone's story, write your own.\" - Cory Doctorow",
  // "\"Gamers are not a minority, and they're not going anywhere.\" - Cory Doctorow",
  // "\"The great thing about a free market is that it gives everyone a voice, the terrible thing about a free market is that it gives everyone a voice.\" - Cory Doctorow",
  // "\"When people talk about business-friendly environments, they're not talking about environments that are friendly to your business, they're talking about environments that are friendly to someone else's business, and hostile to yours.\" - Cory Doctorow",
  "\"Security is not a product, it's a process.\" - Cory Doctorow",
  // "\"Fame is the industrial disease of creativity.\" - Cory Doctorow",
  // "\"Being civil to people is just the grease that keeps society from grinding itself to death.\" - Cory Doctorow",
  "\"The future is here. It's just not evenly distributed yet.\" - Cory Doctorow",
  // "\"It's not every day you get to have a conversation with someone who could, if they wanted to, end your entire species.\" - Cory Doctorow",
  // "\"Utopia is a process, not a destination.\" - Cory Doctorow",
  // "\"Love is when you take off your clothes and offer them to your friends.\" - Cory Doctorow",
  "\"Information doesn't want to be free, people do.\" - Cory Doctorow",
  "\"DRM [Digital Rights Management] isn't a technology, it's a lawsuit waiting to happen.\" - Cory Doctorow",
  // "\"There's no such thing as a stupid question, but there sure are a lot of inquisitive idiots.\" - Cory Doctorow",
  // "\"You can't sell a book if no one has heard of it, but you also can't give a book away if no one has heard of it.\" - Cory Doctorow",
  // "\"To make mistakes is human; to propagate mistakes across multiple copies of a work is to generate comedy gold.\" - Cory Doctorow",
  // "\"The first thing we do, let's kill all the lawyers, because when the lawyers are gone, the internet will be free.\" - Cory Doctorow",
  // "\"Fighting for free expression isn't pointless just because you will never get a perfect victory.\" - Cory Doctorow",
  // "\"You can measure the cost of a soda in dollars and cents, but the true price is paid in calories and morbidity.\" - Cory Doctorow"
]

export default quotes;