import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col
} from "react-bootstrap";

function Sidebar({ routes, image, background }) {
  const { loginWithRedirect, logout } = useAuth0();
  let location = useLocation();
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hidden) {
        return null;
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };
  return (
    <>
      <div className="sidebar" data-color={background} data-image={image}>
        <div className="sidebar-wrapper d-flex flex-column justify-content-between">
          <div>
            <div className="logo my-0 pl-2 py-3 pr-3">
              <Link to="/dashboard" className="simple-text logo-normal m-2 flex-fill p-0">
                <img
                    src={require("assets/img/wmp-logo-01.png")}
                    alt="watch-my-page-logo"
                    className="img-fluid"
                />
              </Link>
            </div>
            <Nav as="ul">
              {createLinks(routes)}
            </Nav>
          </div>
          <div>
            <div className="text-center m-4">
              <a className="text-light" href="#" onClick={() => logout({ returnTo: "https://watchmypage.com/" })}>
                <i className="fal fa-sign-out-alt" />&nbsp;&nbsp;
                Sign Out
              </a>
            </div>
            <div className="text-center mb-0">
              <a className="text-light" href="https://watchmypage.zendesk.com/hc/en-us/signin?return_to=https%3A%2F%2Fwatchmypage.zendesk.com%2Fhc%2Fen-us">
                <i className="fal fa-life-ring" />&nbsp;&nbsp;
                Help and Support
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

let linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element])
};

Sidebar.defaultProps = {
  image: "",
  background: "black",
  routes: []
};

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf([
    "black",
    "azure",
    "green",
    "orange",
    "red",
    "purple"
  ]),
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        ...linkPropTypes,
        icon: PropTypes.string
      }),
      PropTypes.shape({
        collapse: true,
        path: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        icon: PropTypes.string,
        views: PropTypes.shape({
          ...linkPropTypes,
          mini: PropTypes.string
        })
      })
    ])
  )
};

export default Sidebar;
