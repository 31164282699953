import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import axios from "axios";
import { Checkbox } from "rsuite";
import {useAuth0} from "@auth0/auth0-react";
import SitewideDataContext from "../../utils/sitewideContext";
import Preloader from "../../components/preloader";

import "../../constants/service_constants";

function overlayItem(text) {
  return(
    <Tooltip id="statusItem">
      {text}
    </Tooltip>
  )
}

async function checkDomain(domain) {
    try {
        const response = await axios.get(API_ADDRESS + CHECK_DOMAIN_ENDPOINT + domain);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

function AddSite() {
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [newSiteData, setNewSiteData] = useState({
    site_url: "",
    site_name: "",
    spider: true,
    local_only: false,
    save_html: true,
    take_screenshot: true,
    save_pdfs: false,
    ignore_case: false,
  });
  const [isValidURL, setIsValidURL] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    try {
      getAccessTokenSilently().then((token) => {
        axios.get(API_ADDRESS + USER_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(
            (response) => {
              setUserData(response.data);
              if (response.data.owned_sites >= response.data.site_licenses && response.data.self_service) {
                alert('You currently don\'t have any available site licenses.  We will redirect you to the billing portal to add more licenses.');
                window.location.href = response.data.payment_link;
              } else if (response.data.owned_sites >= response.data.site_licenses) {
                alert('You currently don\'t have any available site licenses.  Please contact support to add more sites.');
                history.push('/dashboard');
              }
            }
        ).catch(
            (error) => {
              alert('Something went wrong.  Please reload the page and try again.');
              history.push('/dashboard');
            }
        );
      });
    } catch (error) {
      alert('Something went wrong.  Please reload the page and try again.');
      history.push('/dashboard');
    }
  }, []);

  const handleDomainChange = (e) => {
    const domain = e.target.value.trim();
    const domainRegex = /^(?!-)(?!.*--)(?!.*\.$)[A-Za-z0-9-]{1,63}(?:\.[A-Za-z0-9-]{1,63})*\.[A-Za-z]{2,19}$/;
    if (domainRegex.test(domain)) {
      setNewSiteData({ ...newSiteData, site_url: domain });
      setIsValidURL(true);
      setSubmitDisabled(!newSiteData.site_name);
    } else {
      setNewSiteData({ ...newSiteData, site_url: domain });
      setIsValidURL(false);
      setSubmitDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPageLoading(true);
    try {
      // const domainCheckResult = await checkDomain(newSiteData.site_url);
      // if (domainCheckResult.http_status_code === 200) {
        const token = await getAccessTokenSilently();
        const response = await axios.post(API_ADDRESS + SITE_ENDPOINT, newSiteData, {
          headers: {Authorization: `Bearer ${token}`}
        });
        // setApiResponse(response.data);
        alert('Site created successfully');
        history.push('/dashboard');
        setPageLoading(false)
      // } else {
      //   if (domainCheckResult.error) {
      //       alert("Domain check failed with unknown error, please check that you've entered the correct domain and that it is valid, registered, and configured correctly.");
      //       setPageLoading(false);
      //   } else if (domainCheckResult.redirect_url) {
      //     alert("This domain is redirecting to " + domainCheckResult.redirect_url + ".  Please enter the final destination domain.");
      //   } else {
      //     alert("Domain check failed with status code: " + domainCheckResult.http_status_code + " - " + domainCheckResult.reason +  ".  Please check that your domain and site are configured correctly.");
      //   }
      //   setPageLoading(false);
      // }
    } catch (error) {
      // console.error(error);
      alert('Failed to create site');
      setPageLoading(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title as="h4">
              <Row>
                <Col>
                  Add a new site {
                  userData.site_licenses ?
                    (<small>({userData.site_licenses - userData.owned_sites} licenses remaining)</small>) :
                      (<small></small>)}
                </Col>
              </Row>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Container fluid>
              <Row>
                {pageLoading ? (
                    <div
                        className="position-absolute"
                        style={{
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'top',
                          paddingTop: '200px',
                          backgroundColor: 'rgba(255, 255, 255, 0.9)',
                          zIndex: 1,
                        }}
                    >
                      <Preloader text textOverride={"Checking domain"} />
                    </div>
                ) : null}
                <Col>
                  <h5>Site Details</h5>
                  <hr/>
                  <Form.Group>
                    <label>Site Name&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Friendly name of the site you want to crawl, like 'Watch My Page'.  We just use this to help you manage your sites more easily - it doesn't affect crawling.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger></label>
                    <Form.Control
                      value={newSiteData.site_name}
                      isInvalid={!newSiteData.site_name}
                      placeholder="Friendly Name of the site you're adding"
                      onChange={(e) => {
                        setNewSiteData({ ...newSiteData, site_name: e.target.value });
                        setSubmitDisabled(!e.target.value || !isValidURL);
                      }}
                      disabled={pageLoading}
                    />
                    <Form.Control.Feedback type="invalid">Site name must not be blank</Form.Control.Feedback>
                  </Form.Group>
                  <br/>
                  <Form.Group>
                    <label>Domain&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("This is the actual domain of the site you want to crawl.  Must be a valid domain or subdomain with no scheme, protocol, port, path, params or file.  Like 'www.watchmypage.com' or 'watchmypage.com'.  If you use a CNAME record to redirect to your root domain, then you should use whatever level your A record is.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger></label>
                    <Form.Control
                      value={newSiteData.site_url}
                      isInvalid={!isValidURL}
                      placeholder="site.com or www.site.com"
                      onChange={handleDomainChange}
                      disabled={pageLoading}
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Please enter a valid domain.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <h5>Scan Settings</h5>
                  <em>These settings will become available in the future.</em>
                  <hr/>
                  <Checkbox
                    className="ml-4"
                    checked={newSiteData.spider}
                    onChange={(event, other, syntheticEvent) => {
                      setNewSiteData({...newSiteData, spider: syntheticEvent.target.checked});
                    }}
                    id="spider"
                    disabled
                  >
                    <span className="ml-4">Automatically Spider Internal Links&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Automatically spider internal links to find new pages to scan.  Currently, when this is disabled, we will only scan your homepage.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <br/>
                  <Checkbox
                    className="ml-4"
                    checked={newSiteData.local_only}
                    onChange={(event, other, syntheticEvent) => {
                      setNewSiteData({...newSiteData, local_only: syntheticEvent.target.checked});
                    }}
                    id="local_only"
                    disabled
                  >
                    <span className="ml-4">Check external links and resources&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("When enabled, each crawl will send a series of HTTP requests to every external link and resource used in your site to verify that they work properly.  Disabling this feature will disable broken link checking for external resources.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <br/>
                  <Checkbox
                    className="ml-4"
                    checked={newSiteData.save_html}
                    onChange={(event, other, syntheticEvent) => {
                      setNewSiteData({...newSiteData, save_html: syntheticEvent.target.checked});
                    }}
                    id="save_html"
                    disabled
                  >
                    <span className="ml-4">Save internal HTML files&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("This controls whether the crawler will archive the HTML source of each page on your site.  With this feature disabled crawls will still occur, but Watch My Page can no longer check for changes from one crawl to the next, or show a diff of HTML files in your site.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <br/>
                  <Checkbox
                    className="ml-4"
                    checked={newSiteData.save_pdfs}
                    onChange={(event, other, syntheticEvent) => {
                      setNewSiteData({...newSiteData, save_pdfs: syntheticEvent.target.checked});
                    }}
                    id="save_pdfs"
                    disabled
                  >
                    <span className="ml-4">Save internal PDF files&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Enabling this feature means we will download and store PDF files that are linked and hosted on your site.  With this disabled, we will still check whether the PDF is available, but we won't archive a copy of the file.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <br/>
                  <Checkbox
                    className="ml-4"
                    checked={newSiteData.take_screenshot}
                    onChange={(event, other, syntheticEvent) => {
                      setNewSiteData({...newSiteData, take_screenshot: syntheticEvent.target.checked});
                    }}
                    id="take_screenshot"
                    disabled
                  >
                    <span className="ml-4">Save page screenshots&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Save a screenshot of each page in your site each time we crawl it.  With this off we will still crawl your site, but we won't take the screenshot.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <br/>
                  <Checkbox
                    className="ml-4"
                    checked={newSiteData.ignore_case}
                    onChange={(event, other, syntheticEvent) => {
                      setNewSiteData({...newSiteData, ignore_case: syntheticEvent.target.checked});
                    }}
                    id="ignore_case"
                    disabled
                  >
                    <span className="ml-4">Case insensitive internal links&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("Handle internal links as case insensitive by converting them to lowercase when they're collected.  This is best for case-insensitive webservers like Windows Server.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger>
                    </span>
                  </Checkbox>
                  <br/><br/>
                  <Form.Group>
                    <label>Scan Frequency&nbsp;&nbsp;
                      <OverlayTrigger placement="bottom" overlay={overlayItem("The highest available scan frequency is daily, but you can reduce the frequency if your site changes less or you have less stringent requirements and would prefer a less cluttered archive.")}>
                        <i className="fal fa-circle-info"/>
                      </OverlayTrigger></label>
                    <Form.Control as="select" defaultValue="2" disabled={true}>
                      <option id="1" value={86400}>Daily</option>
                      <option id="2" value={604800}>Weekly</option>
                      <option id="3" value={1209600}>Bi-weekly</option>
                      <option id="4" value={2592000}>Monthly</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-4">
                  <Button className="btn-fill btn-wd" type="submit" variant="primary" onClick={handleSubmit} disabled={submitDisabled || pageLoading}>Add Site</Button>
                </Col>
              </Row>
            </Container>
          </Card.Body>
          <Card.Footer/>
        </Card>
      </Container>
    </>
  );
}

export default AddSite;
